import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { FullVisitor } from '../core/cockpit/visitor';
import { SigninComponent } from './signin/signin.component';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	constructor(
		private modalService: BsModalService,
		private router: Router,
		) { }

	bsModalRef!: BsModalRef;
	currentUser!: FullVisitor;

	async getCurrentUser() {
		try {
			const user = await Auth.currentAuthenticatedUser();
			return user;
		} catch (err) {
			console.log(err);
		}
	}
	

	checkUser() {
		Auth.currentAuthenticatedUser({
			bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
		}).then(user => {
			// console.log(user);
			this.router.navigate(['lobby']);
		}).catch(err => {
			console.log(err)
			this.signIn();
		});
	}
	
	signIn() {
		this.bsModalRef = this.modalService.show(SigninComponent, { class: "modal-md modal-dialog-centered", backdrop: "static" });
	}

	deleteUser() {
		Auth.currentAuthenticatedUser({
			bypassCache: true // Optional, By default is false. 
		}).then((user) => {
			user.deleteUser((error: any, data: any) => {
				if (error) { throw error; }
				// Do something to delete user data in your system 
				// Log the user out 
				Auth.signOut({ global: true });
			});
		}).catch(err => console.log(err));
	}
}
