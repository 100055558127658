import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Auth } from 'aws-amplify';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SigninComponent } from '../signin/signin.component';
import { SignupComponent } from '../signup/signup.component';

@Component({
  selector: 'app-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.scss']
})
export class CompleteComponent implements OnInit {

  email: string = '';
  password: string = '';
  code: string = '';
  isValid: boolean = true;
  errorMessage: string = '';
  codeSent: boolean = false; 
  
  constructor(
    public bsModalRef: BsModalRef,
    private router: Router,
    private modalService: BsModalService,
    public translate: TranslateService,
  ) { }

  // forgotPassword() {
  //   Auth.forgotPassword(this.email.toString())
  //   .then()
  //   .catch(err => console.log(err));
  // }

  completeNewPassword() {
    Auth.forgotPasswordSubmit(this.email.toString(), this.code.toString(), this.password.toString())
    .then(data => {
      this.isValid = true;
      this.bsModalRef.hide();
      this.bsModalRef = this.modalService.show(SigninComponent, { class: "modal-md modal-dialog-centered", backdrop: "static" });
    })
    .catch(error => {
      this.isValid = false;
      console.log(error);
     
      if (error.code) {
        // this.errorMessage = error.code;
        switch (error.message) {
          case "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
            this.translate.get('auth.longerPass').subscribe((res: string) => {
              this.errorMessage = res;
            });
            console.log(error.message);
            break;
          case 'AuthError - Username cannot be empty':
            this.translate.get('auth.emptyUser').subscribe((res: string) => {
              this.errorMessage = res;
            });
            console.log(error.message);
            break;
          case 'Invalid verification code provided, please try again.':
            this.translate.get('auth.wrongCode').subscribe((res: string) => {
              this.errorMessage = res;
            });
            console.log(error.message);
            break;
          // case 'Password did not conform with policy: Password not long enough':
          //   console.log(error.message);
          //   break;
          // case 'User is not confirmed.':
          //   this.translate.get('auth.notConfirmed').subscribe((res: string) => {
          // 	this.errorMessage = res;
          //   });
          //   console.log(error.message);
          //   break;
          // case 'Incorrect username or password.':
          //   console.log(error.message);
          //   this.translate.get('auth.incorrect').subscribe((res: string) => {
          // 	this.errorMessage = res;
          //   });
          //   break;
          // case 'User does not exist.':
          //   console.log(error.message);
          //   this.translate.get('auth.notExists').subscribe((res: string) => {
          // 	this.errorMessage = res;
          //   });
          //   break;
          default:
            this.translate.get('auth.checkFields').subscribe((res: string) => {
              this.errorMessage = res;
            });
            console.log(error.message);
        }

      } else {
        this.translate.get('auth.emptyUser').subscribe((res: string) => {
          this.errorMessage = res;
        });
      }
    });
  }

  registration(){
    this.bsModalRef.hide();
    this.bsModalRef = this.modalService.show(SignupComponent, { class: "modal-md modal-dialog-centered", backdrop: "static" });
  }

  signIn() {
		this.bsModalRef.hide();
		this.bsModalRef = this.modalService.show(SigninComponent, { class: "modal-md modal-dialog-centered", backdrop: "static" });
	}
  
  ngOnInit(): void {
   
  }
}
