import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ScriptsService } from 'src/app/core/scripts.service';
declare var Vimeo: any;

@Component({
  selector: 'app-vimeo',
  templateUrl: './vimeo.component.html',
  styleUrls: ['./vimeo.component.scss']
})
export class VimeoComponent implements OnInit {

  list: any[] = [];
  modalId!: number;
  vimeoIsLoaded!: boolean;

  constructor(
    private modalService: BsModalService,
    scriptService: ScriptsService
  ) {
    scriptService.load("vimeo").then((data) => {
      this.vimeoIsLoaded = true;
      this.init();
    });
  }

  public video: any;
  public player: any;

  ngOnInit(): void {
    // console.log(this.list);
    this.video = this.list;
    this.modalId = this.modalId;
  }

  init() {
    var options = {
      id: this.video
    };

    if (this.vimeoIsLoaded) {
      this.player = new Vimeo.Player("playerVM", options);
      this.startVideo();
    }
  }

  startVideo() {
    this.player
      .play()
      .then(function () {
        // the video was played
      })
      .catch(function (error: { name: any; }) {
        switch (error.name) {
          case "PasswordError":
            // the video is password-protected and the viewer needs to enter the
            // password first
            break;

          case "PrivacyError":
            // the video is private
            break;

          default:
            // some other error occurred
            break;
        }
      });
  }

  closeModal(modId?: number) {
    if (this.modalId) {
      this.modalService.hide(this.modalId);
    } else {
      this.modalService.hide(modId);
    }
  }
}
