<div class="desktop d-lg-block d-none">
    <app-imagemap [data]="data"></app-imagemap>
</div>
<div class="mobile d-flex flex-column d-lg-none bg-light">
    <ul class="navbar-nav bg-primary my-3">
        <li class="nav-item border-bottom" *ngFor="let item of data.xxl.coords| slice:0:6; let i=index">
          <a class="nav-link text-center h5 text-white" aria-current="page" [routerLink]="'/'+item.actionParam">{{item.tooltip | translate}}</a>
        </li>
    </ul>
    <app-mobile-images [data]="mobileImages"></app-mobile-images>
    <app-footer></app-footer>
</div>
