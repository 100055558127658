import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/core/language.service';

@Component({
  selector: 'app-langswitch',
  templateUrl: './langswitch.component.html',
  styleUrls: ['./langswitch.component.scss']
})
export class LangswitchComponent implements OnInit {

  translation: any;
  constructor(
    public translate: TranslateService,
    public _localSettings: LanguageService
  ) {
    const storedLang: string = this._localSettings.getLanguage();
    if (storedLang !== "") {
      this.translate.setDefaultLang(storedLang);
      translate.use(storedLang);
    } else {
      this.translate.setDefaultLang('bg');
      translate.use('bg');
    }
  }


  changeLang(lang: string) {
    this.translate.use(lang);
    this._localSettings.setLanguage(lang);
    window.location.reload();
  }

  ngOnInit() {
   
  }

}
