import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IframeModalComponent } from 'src/app/modals/iframe-modal/iframe-modal.component';
import { StandComponent } from 'src/app/modals/stand/stand.component';
import { YoutubeComponent } from 'src/app/modals/youtube/youtube.component';
import { Coords, Imagemap } from './imagemap';
declare function imageMapResize(): void;

@Component({
  selector: 'app-imagemap',
  templateUrl: './imagemap.component.html',
  styleUrls: ['./imagemap.component.scss']
})
export class ImagemapComponent implements OnInit {

  @Input() data!: Imagemap;

  showTooltip: boolean = false;
  tooltip: string = '';
  top!: number;
  left!: number;
  public innerWidth: any; 
  imageData: any;
  @ViewChild('ttp') myIdentifier!: ElementRef;

  bsModalRef!: BsModalRef;
  constructor(
    public translate: TranslateService,
    private modalService: BsModalService,
    private router: Router
  ) {}

  mouseCoordinates(e: any) {
    // console.log(e)
    var width = this.myIdentifier.nativeElement.offsetWidth;
    var height = this.myIdentifier.nativeElement.offsetHeight;
    
      // console.log('Width:' + width);
      // console.log('Height: ' + height);

    if (height < 50) {
      this.top = e.pageY - 45;
    } else if (height >= 50 && height < 70) {
      this.top = e.pageY - 65;
    } else if (height >= 50 && height < 104) {
      this.top = e.pageY - 85;
    } else if (height >= 105) {
      this.top = e.pageY - 110;
    }
    
    this.left = e.pageX - (width / 2);
  }
  showTooltips(element: string) {
    this.showTooltip = true;
    this.translate.get(element).subscribe((res: string) => { this.tooltip = res; });

  }
  hideTooltips() {
    this.showTooltip = false;
    this.tooltip = '';
  }

  imageResized() {
    imageMapResize(); // Javascript function in imageMapResizer.min.js 
  }

  onClick(item: Coords) {
    switch (item.action) {
      case 'openVideoModal()':
        this.openVideoModal(item.actionParam)
        break;
      case 'openModal()':
        this.openModal(item.actionParam)
        break;
      case 'goToPage()':
        this.goToPage(item.actionParam)
        break;
      case 'goToExtPage()':
        this.goToExtPage(item.actionParam)
        break;
      case 'openIFrameModal()':
        let hash = item.actionParam.split(',');
        // console.log(hash)
        this.openIFrameModal(hash[0], hash[1])
        break;
      default: this.openModal('');
    }
  }


  public openVideoModal(videoId: string) {
    const initialState = {
      list: [videoId],
    };
    this.bsModalRef = this.modalService.show(YoutubeComponent, {
      initialState,
      class: "modal-lg",
    });
  }

  public openModal(id: any) {
    const initialState = {
      item: id,
      modalId: id
    };
    this.bsModalRef = this.modalService.show(StandComponent, {
      initialState,
      class: "place-modal modal-xxl",
      ignoreBackdropClick: false,
      backdrop: true,
      id: id
    });
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    // console.log(this.innerWidth)
    this.changeBg();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    this.changeBg();
  }

  changeBg() {
    if (innerWidth >= 1536) {
      this.imageData = this.data.xxl;
    } else if ((innerWidth >= 1400) && (innerWidth < 1535)) {
      this.imageData = this.data.xl;
    } else if ((innerWidth >= 1366) && (innerWidth < 1399)) {
      this.imageData = this.data.lg;
    } else if (innerWidth < 1365) {
      this.imageData = this.data.md;
    }
  }

  goToExtPage(page: string) {
    window.open(page, "_blank");
  }

  goToPage(page: string) {
    this.router.navigate([`/${page}`]);
  }

  openIFrameModal(frameHash: string, frameId?: string) {
    const initialState = {
      list: { place: frameId, iframe: frameHash },
      modalId: 901,
    };
    this.bsModalRef = this.modalService.show(IframeModalComponent, {
      initialState,
      class: "modal-xl modal-dialog-centered",
      ignoreBackdropClick: true,
      backdrop: true,
      id: 901,
    });
  }

}

