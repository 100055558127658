import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Imagemap } from 'src/app/core/imagemap/imagemap';
import { Places } from 'src/app/core/urbo/places';
import { UrboService } from 'src/app/core/urbo/urbo.service';
import { StandComponent } from 'src/app/modals/stand/stand.component';

@Component({
  selector: 'app-expo-hall2',
  templateUrl: './expo-hall2.component.html',
  styleUrls: ['./expo-hall2.component.scss']
})
export class ExpoHall2Component implements OnInit, OnDestroy {
  private unscubscribe = new Subject();
  errorMessage: any;
  loading: boolean = false;
  places!: Places[];
  bsModalRef!: BsModalRef;
  
  data: Imagemap = {
    title: "pages.expoHall1.title",
    xxl: {
      image: "../../../assets/images/bg/expo-hall-2-xxl.jpg",
      coords: []
    },
    xl: {
      image: "../../../assets/images/bg/expo-hall-2-xl.jpg",
      coords: []
    },
    lg: {
      image: "../../../assets/images/bg/expo-hall-2-lg.jpg",
      coords: []
    },
    md: {
      image: "../../../assets/images/bg/expo-hall-2-md.jpg",
      coords: []
    }
  }
  
  
  constructor(
    private urbo: UrboService,
    private modalService: BsModalService,
    public translate: TranslateService,
    private titleService: Title
  ) { }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }


  ngOnInit(): void {
    this.urbo 
      .getPlacesHall2()
      .pipe(takeUntil(this.unscubscribe))
      .subscribe({
        next: (places: any) => {
          // console.log(places)
          this.places = places;
          this.places.map(p => {
            let coords = JSON.parse(p.mol);
            this.data.xxl.coords = [...this.data.xxl.coords, {
              tooltip: p.name, 
              coords: coords.xxl, 
              shape: "poly", 
              action: "openModal()", 
              actionParam: p.id
            }];
            this.data.xl.coords = [...this.data.xl.coords, {
              tooltip: p.name, 
              coords: coords.xl, 
              shape: "poly", 
              action: "openModal()", 
              actionParam: p.id
            }];
            this.data.lg.coords = [...this.data.lg.coords, {
              tooltip: p.name, 
              coords: coords.lg, 
              shape: "poly", 
              action: "openModal()", 
              actionParam: p.id
            }];
            this.data.md.coords = [...this.data.md.coords, {
              tooltip: p.name, 
              coords: coords.md, 
              shape: "poly", 
              action: "openModal()", 
              actionParam: p.id
            }];
          })
        },
        error: (err) => (this.errorMessage = err),
      });

      this.translate.get(this.data.title).subscribe((res: string) => {
        this.setTitle(res);
      });
  }

  public openModal(id: any) {
    const initialState = {
      item: id,
      modalId: id
    };
    this.bsModalRef = this.modalService.show(StandComponent, {
      initialState,
      class: "place-modal modal-xxl",
      ignoreBackdropClick: false,
      backdrop: true,
      id: id
    });
  }

  ngOnDestroy() {
    this.unscubscribe.next();
    this.unscubscribe.complete();
  }
}
