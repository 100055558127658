<div id="bg" class="d-lg-block d-none">
    <img src="../../../assets/images/bg/event-hall.jpg" class="full-screen" [alt]="'pages.eventHall.title' | translate" />
</div>
<div class="static-modal-wrapper d-flex flex-column min-vh-100">
    <div class="modal-dialog my-lg-auto modal-xl mt-4">
        <div class="modal-content opacity-9">
            <div class="modal-header border-bottom-0 py-4">
                <img src="../../../assets/images/logos/expo-summer-logo-white-w125.png">
                <h1 class="text-white text-uppercase text-end">{{'pages.eventHall.program' | translate}}</h1>
            </div>
            <div class="modal-body p-4">
                <div class="row overflow-auto custom-scroll d-none d-lg-flex">
                    <drag-scroll [drag-disabled]="false" [scrollbar-hidden]="false" class="card-group" #nav>
                        <app-event-items drag-scroll-item class="card border-0 bg-transparent d-inline-flex h-100 me-4 pb-4" *ngFor="let seminar of seminars" [item]="seminar"></app-event-items>
                    </drag-scroll>
                    <div class="row mt-4">
                        <div class="col-12 text-center">
                            <button role="button" class="btn btn-link text-white" (click)="moveLeft()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-chevron-double-left" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                    <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                </svg>
                            </button>
                            <button role="button" class="btn btn-link text-white" (click)="moveRight()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z" />
                                    <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row d-lg-none">
                    <app-event-items drag-scroll-item class="col-12 col-sm-6 pb-4" *ngFor="let seminar of seminars" [item]="seminar"></app-event-items>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="mobile d-flex flex-column d-lg-none">
    <app-footer></app-footer>
</div>
