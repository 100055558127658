import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IframeModalComponent } from 'src/app/modals/iframe-modal/iframe-modal.component';
import { NavbarService } from './navbar.service';

@Component({
	selector: 'app-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

	constructor(
		public nav: NavbarService,
		private modalService: BsModalService
	) { }
	isCollapsed = true;
	bsModalRef!: BsModalRef;

	ngOnInit(): void {
	}

	closeModal() {
		this.modalService.hide();
	}

	openIFrameModal() {
		const initialState = {
			list: { place: '', iframe: 'GhompKDFD6tXAT7U0Fmf3ZyYaff753O8' },
			modalId: 903,
		};
		this.bsModalRef = this.modalService.show(IframeModalComponent, {
			initialState,
			class: "modal-xl modal-dialog-centered",
			ignoreBackdropClick: true,
			backdrop: true,
			id: 903,
		});
	}
	
}
