import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingIndicatorService } from '../loader/loading-indicator.service';
import {environment} from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private loadingIndicatorService: LoadingIndicatorService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // return next.handle(request);
    
      // emit onStarted event before request execution
      this.loadingIndicatorService.onStarted(request);
      
     
 
    let jsonRequest: HttpRequest<any> = request.clone({
      setHeaders: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + environment.urboToken,
      }
    });

    return next.handle(jsonRequest).pipe (
      finalize(() => this.loadingIndicatorService.onFinished(request))
    );
  }
}
