import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
declare var YT: any;

@Component({
  selector: 'app-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.scss']
})
export class YoutubeComponent implements OnInit {

  list: any[] = [];
  modalId!: number;

  public YT: any;
  public video: any;
  public player: any;
  public reframed: Boolean = false;
  isRestricted = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  ytApiIsLoaded!: boolean;

  constructor(
    public bsModalRef: BsModalRef,
    private modalService: BsModalService
  ) {
  }

  ngOnInit() {
    // console.log(this.list);
    this.video = this.list;
    this.modalId = this.modalId;
    this.init();
  }
  init() {
    // Return if Player is already created
    if ((<any>window)["YT"]) {
      this.startVideo();
      return;
    }

    var tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode!.insertBefore(tag, firstScriptTag);

    /* 3. startVideo() will create an <iframe> (and YouTube player) after the API code downloads. */
    (<any>window)["onYouTubeIframeAPIReady"] = () => this.startVideo();
  }

  startVideo() {
    this.reframed = false;
    this.player = new (<any>window)["YT"].Player("player", {
      videoId: this.video,
      playerVars: {
        autoplay: 1,
        modestbranding: 1,
        controls: 1,
        disablekb: 1,
        rel: 0,
        showinfo: 0,
        fs: 1,
        playsinline: 1,
      },
      events: {
        onStateChange: this.onPlayerStateChange.bind(this),
        onError: this.onPlayerError.bind(this),
        onReady: this.onPlayerReady.bind(this),
      },
    });
  }

  onPlayerReady(event:any) {
    if (this.isRestricted) {
      event.target.mute();
      event.target.playVideo();
    } else {
      event.target.playVideo();
    }
  }

  /* 5. API will call this function when Player State changes like PLAYING, PAUSED, ENDED */
  onPlayerStateChange(event:any) {
    // console.log(event);
    switch (event.data) {
      case (<any>window)["YT"].PlayerState.PLAYING:
        if (this.cleanTime() == 0) {
          console.log("started " + this.cleanTime());
        } else {
          console.log("playing " + this.cleanTime());
        }
        break;
      case (<any>window)["YT"].PlayerState.PAUSED:
        if (this.player.getDuration() - this.player.getCurrentTime() != 0) {
          console.log("paused" + " @ " + this.cleanTime());
        }
        break;
      case (<any>window)["YT"].PlayerState.ENDED:
        console.log("ended ");
        break;
    }
  }

  cleanTime() {
    return Math.round(this.player.getCurrentTime());
  }

  onPlayerError(event:any) {
    switch (event.data) {
      case 2:
        console.log("" + this.video);
        break;
      case 100:
        break;
      case 101 || 150:
        break;
    }
  }
  stopVideo() {
    this.player.stopVideo();
  }
  closeModal(modId?: number) {
    if (this.modalId) {
      this.modalService.hide(this.modalId);
    } else {
      this.modalService.hide(modId);
    }
  }

}
