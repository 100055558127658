// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'http://expo22.danailov.eu/cms/',
  urboApiUrl: 'https://api.urboapp.com/api/',
  apiToken: '00770c703769dd201bad6615701e0a',
  urboToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjgwMGMwNjRmZWU0NjdkNGUzZmQ3YjI0NzBmYjJmMDU1NzhiNWEwNzJhN2RmOTk4MDY0ZWNiNGFkZTY5YzU3NjNiYjI3YjMwZTgzNWUwODcxIn0.eyJhdWQiOiIxIiwianRpIjoiODAwYzA2NGZlZTQ2N2Q0ZTNmZDdiMjQ3MGZiMmYwNTU3OGI1YTA3MmE3ZGY5OTgwNjRlY2I0YWRlNjljNTc2M2JiMjdiMzBlODM1ZTA4NzEiLCJpYXQiOjE2NzIzODU4ODIsIm5iZiI6MTY3MjM4NTg4MiwiZXhwIjoxNzAzOTIxODgyLCJzdWIiOiIyNiIsInNjb3BlcyI6W119.asoKrS26NgI_3YthU1K_2ka-j173YHUwL3TcuOGb25B8YdKtAbw_lr8xh6BaJ6wFUGxy3C6xSScqjJ0EH3hppHAxQTd0q015L6fe5TNLHYLPS1QYKG5AbxRqgf5O29hoQzQWeg2gPdxngIzziajnLOHqxyblXceOuRA60V92IQHf89DEgPIVIcIFLNjoTXjPRnEvy1tFvoF7f0Jn800XOEt-w4_9GHCaZ5LrMMjr39FgLAhDvGLDPDcJgYk5vEnlJhXqYotouNxSQotOE2lACokvIfzjugTddIc9SPao3AcSfCbeGX7WAO1FmmEjrEmis2jbj_JJO2w8XvOaarSn56sAumxjpuSF1SN74U_xgijIp4ZNaj09c2BQd8oAH45OHuCsHbhCe8Mg-93v8Hf0RBPQYBTdy-n03oYIBPJmXC0mF8xIAiYF6qWeZc_4vCJnOidB8LUtNUBNmtDEylC4Zh6guIiR_P7BnSfYQ5lC8G0mq_QxhbSFT5CLKzLaboRqtbWCFwT1XOLpaIgJ3qebg5Und3dhh54nsuRY33Z1YGg1PQDl6rUUB_AibBe7DQ94aSN1d6Zr3LUg9gwbR5oZOCMLQm0SJrdqDipEtSbpH9ZdBfv1bARiYvq0d_JKWboU-YKcSnvnCCLcF7G3KoepC6CRViDpXwmljmKMXDzTal4'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
