import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import Amplify, { Auth } from 'aws-amplify';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LogintmpComponent } from './temp/login/logintmp.component';
import { SignUptmpComponent } from './temp/sign-up/sign-uptmp.component';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageService } from './core/language.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HomeComponent } from './pages/home/home.component';
import { ImagemapComponent } from './core/imagemap/imagemap.component';
import { YoutubeComponent } from './modals/youtube/youtube.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SigninComponent } from './auth/signin/signin.component';
import { SignupComponent } from './auth/signup/signup.component';
import { LobbyComponent } from './pages/lobby/lobby.component';
import { AuthService } from './auth/auth.service';
import { CheckPasswordsDirective } from './auth/check-passwords.directive';
import { NavigationComponent } from './shared/navigation/navigation.component';
import { ExpoHall1Component } from './pages/expo-hall1/expo-hall1.component';
import { ExpoHall2Component } from './pages/expo-hall2/expo-hall2.component';
import { EventHallComponent } from './pages/event-hall/event-hall.component';
import { MeetingAreaComponent } from './pages/meeting-area/meeting-area.component';
import { VirtualCafeComponent } from './pages/virtual-cafe/virtual-cafe.component';
import { UrboService } from './core/urbo/urbo.service';
import { AuthInterceptor } from './core/urbo/auth.interceptor';
import { ModifyRequestInterceptor } from './core/urbo/modify-request.interceptor';
import { VisitorsComponent } from './pages/meeting-area/visitors/visitors.component';
import { VisitorsModalComponent } from './pages/meeting-area/visitors-modal/visitors-modal.component';
import { VisitorsPipe } from './pages/meeting-area/visitors.pipe';
import { MailchimpService } from './core/mailchimp/mailchimp.service';
import { IframeModalComponent } from './modals/iframe-modal/iframe-modal.component';
import { EventItemsComponent } from './pages/event-hall/event-items/event-items.component';
import { DragScrollModule } from 'ngx-drag-scroll';
import { KeepHtmlPipe } from './core/keep-html.pipe';
import { VimeoComponent } from './modals/vimeo/vimeo.component';
import { NavbarService } from './shared/navigation/navbar.service';
import { CommonModule } from '@angular/common';
import { LangswitchComponent } from './shared/langswitch/langswitch.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GameModalComponent } from './pages/virtual-cafe/game-modal/game-modal.component';
import { MobileImagesComponent } from './core/mobile-images/mobile-images.component';
import { FooterComponent } from './shared/footer/footer.component';
import { StandComponent } from './modals/stand/stand.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ForgottenComponent } from './auth/forgotten/forgotten.component';
import { CompleteComponent } from './auth/complete/complete.component';
import { ExhibitorsModalComponent } from './pages/meeting-area/exhibitors-modal/exhibitors-modal.component';



Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_GHnIc7xg1',
    userPoolWebClientId: '1rpfd7mjem8jm2etbe6b4783uo',
    // authenticationFlowType: 'USER_PASSWORD_AUTH',

  }
});

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    LogintmpComponent,
    SignUptmpComponent,
    HomeComponent,
    LangswitchComponent,
    ImagemapComponent,
    YoutubeComponent,
    SigninComponent,
    SignupComponent,
    LobbyComponent,
    CheckPasswordsDirective,
    NavigationComponent,
    ExpoHall1Component,
    ExpoHall2Component,
    EventHallComponent,
    MeetingAreaComponent,
    VirtualCafeComponent,
    VisitorsComponent,
    VisitorsModalComponent,
    VisitorsPipe,
    IframeModalComponent,
    EventItemsComponent,
    KeepHtmlPipe,
    VimeoComponent,
    GameModalComponent,
    MobileImagesComponent,
    FooterComponent,
    StandComponent,
    ForgottenComponent,
    CompleteComponent,
    ExhibitorsModalComponent
  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'bg',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      useDefaultLang: false
    }),
    ModalModule.forRoot(),
    DragScrollModule,
    CollapseModule.forRoot(),
    PopoverModule
  ],
  providers: [
    LanguageService,
    AuthService,
    UrboService,
    NavbarService,
    MailchimpService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ModifyRequestInterceptor,
      multi: true,
    },
    Title,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
