import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Events } from 'src/app/core/urbo/events';
import { IframeModalComponent } from 'src/app/modals/iframe-modal/iframe-modal.component';
import { VimeoComponent } from 'src/app/modals/vimeo/vimeo.component';

@Component({
  selector: 'app-event-items',
  templateUrl: './event-items.component.html',
  styleUrls: ['./event-items.component.scss']
})
export class EventItemsComponent implements OnInit {

  @Input() item!: Events;
  bsModalRef!: BsModalRef;

  iframeId: string = "UtLwZIwbKLKT8iW5IB8Uoc154DG7Zu5v";
  
  constructor(
    private modalService: BsModalService
  ) {}

  public openModalWithVideo(videoId: string) {
    if (videoId) {
      const initialState = {
        list: [videoId],
        modalId: 200
      };
      this.bsModalRef = this.modalService.show(VimeoComponent, {
        initialState,
        class: "modal-dialog-centered modal-lg",
        ignoreBackdropClick: true,
        backdrop: true,
        id: 200
      });
    }
    
  }

  openIframe(offerId: number) {
    const initialState = {
      list: { place: offerId, iframe: this.iframeId },
      modalId: 101,
    };
    this.bsModalRef = this.modalService.show(IframeModalComponent, {
      initialState,
      class: "modal-xl modal-dialog-centered",
      ignoreBackdropClick: true,
      backdrop: true,
      id: 101,
    });
  }

  ngOnInit(): void {
  }

}