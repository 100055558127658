<div class="mobile">
	<nav class="d-lg-none navbar navbar-expand-lg navbar-dark bg-primary py-0 fixed-top">
		<div class="container-fluid mt-3 py-4" *ngIf="nav.visible; else noMenu">
			<a class="navbar-brand mx-auto" [routerLink]="['/']">
				<img src="../../../assets/images/mobile/logo-mobile.png" class="me-n5">
			</a>
			<button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic">
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse" id="collapseBasic" [collapse]="isCollapsed" [isAnimated]="true">
				<ul class="navbar-nav mt-4">
					<li class="nav-item border-bottom">
						<a [routerLink]="['/lobby']" class="nav-link text-center h5" (click)="isCollapsed = !isCollapsed; closeModal()">
							{{'pages.lobby.title' | translate}}
						</a>
					</li>
					<li class="nav-item border-bottom">
						<a [routerLink]="['/expo-hall-1']"class="nav-link text-center h5" (click)="isCollapsed = !isCollapsed; closeModal()">
							{{'pages.expoHall1.title' | translate}}
						</a>
					</li>
					<li class="nav-item border-bottom">
						<a [routerLink]="['/expo-hall-2']"class="nav-link text-center h5" (click)="isCollapsed = !isCollapsed; closeModal()">
							{{'pages.expoHall2.title' | translate}}
						</a>
					</li>
					<li class="nav-item border-bottom">
						<a [routerLink]="['/meeting-area']"class="nav-link text-center h5" (click)="isCollapsed = !isCollapsed; closeModal()">
							{{'pages.meetingArea.title' | translate}}
						</a>
					</li>
					<li class="nav-item border-bottom">
						<a [routerLink]="['/event-hall']"class="nav-link text-center h5" (click)="isCollapsed = !isCollapsed; closeModal()">
							{{'pages.eventHall.title' | translate}}
						</a>
					</li>
					<li class="nav-item border-bottom">
						<a [routerLink]="" (click)="openIFrameModal()" class="nav-link text-center h5" (click)="isCollapsed = !isCollapsed;">
							{{'pages.travelMarket.title' | translate}}
						</a>
					</li>
					<li class="nav-item border-bottom">
						<a [routerLink]="['/virtual-cafe']"class="nav-link text-center h5" (click)="isCollapsed = !isCollapsed; closeModal()">
							{{'pages.virtualCafe.title' | translate}}
						</a>
					</li>
					<li class="nav-item">
						<a [routerLink]="['/']"class="nav-link text-center h5" (click)="isCollapsed = !isCollapsed; closeModal()">
							{{'pages.home.title' | translate}}
						</a>
					</li>
				</ul>
				<hr>
				<app-langswitch class="text-center"></app-langswitch>
			</div>
		</div>
		<ng-template #noMenu class="container-fluid mt-3 py-4">
			<a class="navbar-brand mx-auto" [routerLink]="['/']">
				<img src="../../../assets/images/mobile/logo-mobile.png">
			</a>
		</ng-template>
	</nav>
</div>

<div class="desktop-nav">
	<div class="d-none d-lg-flex flex-column text-white bg-dark h-100 align-items-end" style="width: 120px;" *ngIf="nav.visible">
		<app-langswitch></app-langswitch>
		<ul class="nav nav-pills flex-column my-auto">
			<li class="nav-item">
				<a [routerLink]="['/lobby']" class="nav-link rounded-pill rounded-end text-uppercase text-end ms-n4 bg-primary text-white mb-1" (click)="closeModal()">
					{{'pages.lobby.title' | translate}}
				</a>
			</li>
			<li class="nav-item">
				<a [routerLink]="['/expo-hall-1']" class="nav-link rounded-pill rounded-end text-uppercase text-end ms-n4 bg-primary text-white mb-1" (click)="closeModal()">
					{{'pages.expoHall1.title' | translate}}
				</a>
			</li>
			<li class="nav-item">
				<a [routerLink]="['/expo-hall-2']" class="nav-link rounded-pill rounded-end text-uppercase text-end ms-n4 bg-primary text-white mb-1" (click)="closeModal()">
					{{'pages.expoHall2.title' | translate}}
				</a>
			</li>
			<li class="nav-item">
				<a [routerLink]="['/meeting-area']" class="nav-link rounded-pill rounded-end text-uppercase text-end ms-n4 bg-primary text-white mb-1" (click)="closeModal()">
					{{'pages.meetingArea.title' | translate}}
				</a>
			</li>
			<li class="nav-item">
				<a [routerLink]="['/event-hall']" class="nav-link rounded-pill rounded-end text-uppercase text-end ms-n4 bg-primary text-white mb-1" (click)="closeModal()">
					{{'pages.eventHall.title' | translate}}
				</a>
			</li>
			<li class="nav-item">
				<a [routerLink]="" (click)="openIFrameModal()" class="nav-link rounded-pill rounded-end text-uppercase text-end ms-n4 bg-primary text-white mb-1" >
					{{'pages.travelMarket.title' | translate}}
				</a>
			</li>
			<li class="nav-item">
				<a [routerLink]="['/virtual-cafe']" class="nav-link rounded-pill rounded-end text-uppercase text-end ms-n4 bg-primary text-white mb-1" (click)="closeModal()">
					{{'pages.virtualCafe.title' | translate}}
				</a>
			</li>
			<li class="nav-item">
				<a [routerLink]="['/']" class="nav-link rounded-pill rounded-end text-uppercase text-end ms-n4 bg-primary text-white mb-1" (click)="closeModal()">
					{{'pages.home.title' | translate}}
				</a>
			</li>

		</ul>

	</div>
</div>
