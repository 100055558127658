<div class="modal-header border-bottom-0 py-4">
    <img src="../../../assets/images/logos/expo-summer-logo-white-w125.png">
    <button type="button" class="btn-close btn-close-white align-self-start" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
</div>
<div class="modal-body">
    <h3 class="text-white text-center mb-4">{{'pages.meetingArea.bookMeetingWith' | translate}} {{data.name}}</h3>
    <div class="form-floating mb-3">
        <select class="form-select" [(ngModel)]="timeSlot" id="timeSlot" aria-label="Floating label select example">
            <option value="09:00">09:00</option>
            <option value="10:00">10:00</option>
            <option value="11:00">11:00</option>
            <option value="12:00">12:00</option>
            <option value="13:00">13:00</option>
            <option value="14:00">14:00</option>
            <option value="15:00">15:00</option>
            <option value="16:00">16:00</option>
            <option value="17:00">17:00</option>
        </select>
        <label for="timeSlot">{{'pages.meetingArea.timeSlot' | translate}}</label>
    </div>
    <div class="form-floating mb-3">
        <select class="form-select" [(ngModel)]="contactBy" id="contactBy" aria-label="Floating label select example">
            <option value="{{'auth.phone' | translate}}">{{'auth.phone' | translate}}</option>
            <option value="{{'auth.email' | translate}}">{{'auth.email' | translate}}</option>
        </select>
        <label for="contactBy">{{'pages.meetingArea.contactBy' | translate}}</label>
    </div>
    
    <div class="mb-3">
        <div class="form-floating">
            <textarea [(ngModel)]="message" class="form-control" placeholder="{{'pages.meetingArea.message' | translate}}" id="textMessage" style="height: 100px"></textarea>
            <label for="textMessage">{{'pages.meetingArea.message' | translate}}</label>
        </div>
    </div>
    <div class="alert alert-secondary" *ngIf="isSent">{{'pages.meetingArea.messageIsSent' | translate}}</div>
</div>
<div class="modal-footer border-top-0">
    <button class="btn btn-outline-light align-self-end" (click)="closeModal()">{{'misc.close' | translate}}</button>
    <button class="btn btn-secondary align-self-end" [disabled]="isSent" (click)="sendContact(timeSlot, contactBy, message)">{{'misc.send' | translate}}</button>
</div>
