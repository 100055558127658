<div class="desktop d-lg-block d-none">
    
    <div class="position-relative d-flex vh-100 w-100">
        <div class="mb-5 mt-auto pb-5 mx-auto z-index-2">
            <button class="btn bnt-link" (click)="signIn()"><img class="icon-100" src="../../../assets/images/icons/enter.png"></button>
        </div>
    </div>
    <app-imagemap [data]="data" class=""></app-imagemap>
</div>
<div class="mobile d-flex flex-column d-lg-none">
    <button class="btn bnt-link my-3" (click)="signIn()"><img class="icon-150" src="../../../assets/images/icons/enter.png"></button>
    <app-mobile-images [data]="mobileImages"></app-mobile-images>
    <app-footer></app-footer>
</div>
