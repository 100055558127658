import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { Offers } from 'src/app/core/urbo/offers';
import { Places } from 'src/app/core/urbo/places';
import { StandVisitor } from 'src/app/core/urbo/stand-visitor';
import { UrboService } from 'src/app/core/urbo/urbo.service';
import { IframeModalComponent } from '../iframe-modal/iframe-modal.component';
import { YoutubeComponent } from '../youtube/youtube.component';

@Component({
	selector: 'app-stand',
	templateUrl: './stand.component.html',
	styleUrls: ['./stand.component.scss'],
	animations: [
		trigger("slideInOut", [
			transition(":enter", [
				style({ transform: "translateX(-100%)" }),
				animate("200ms ease-in", style({ transform: "translateX(0%)" })),
			]),
			transition(":leave", [
				animate("200ms ease-in", style({ transform: "translateX(-100%)" })),
			]),
		]),
	],
})
export class StandComponent implements OnInit {

	private unscubscribe = new Subject();
	errorMessage: any;
	loading: boolean = false;
	item!: number;
	place!: Places;
	visible: boolean = false;
	offers!: Offers[];
	specailOffer!: Offers;
	offerItterable!: boolean;
	currentVisitor!: any;

	infoVisible: boolean = false;
	offersVisible: boolean = false;
	bsModalRef!: BsModalRef;

	constructor(
		private urbo: UrboService,
		private modalService: BsModalService,
		private auth: AuthService,
	) { }

	ngOnInit(): void {
		this.openModal(this.item);
		this.getSpecialOffer(this.item);
		this.getCurrentUser();
	}

	async getCurrentUser() {
		this.auth.getCurrentUser().then(user => {
			// console.log(user);
			this.currentVisitor = user.attributes;
			let visitor: StandVisitor = {
				place_id: this.item,
				expo_id: 1,
				first_name: this.currentVisitor.name,
				last_name: this.currentVisitor.family_name,
				company: this.currentVisitor['custom:company'],
				position: this.currentVisitor['custom:position'],
				email: this.currentVisitor.email,
				phone: this.currentVisitor['custom:phone']
			};
			this.addVisitor(visitor);
		}).catch(err => {
			console.log(err)
		});
	}

	addVisitor(visitor: StandVisitor) {
		this.urbo.addingVisitor(visitor).subscribe({
			next: (entry: any) => { console.log(entry) },
			error: (err) => (this.errorMessage = err),
		});
	}

	openModal(id: number) {
		this.urbo
			.incrementView(id)
			.pipe(takeUntil(this.unscubscribe))
			.subscribe({
				next: (places: any) => {
					this.place = places.data;
				},
				error: (err) => (this.errorMessage = err),
			});
		this.openOffers(id);
	}

	toggleInfo() {
		this.infoVisible = !this.infoVisible;
		this.offersVisible = false;
	}
	toggleOffers() {
		this.offersVisible = !this.offersVisible;
		this.infoVisible = false;
	}

	openChat(link: string) {
		// console.log(link)
		window.open(
			"https://" + link,
			"Windows",
			"width=650,height=350,toolbar=no,menubar=no,scrollbars=yes,resizable=yes,location=no,directories=no,status=no"
		);
	}

	openVideo(videoId: string) {
		const initialState = {
			list: [videoId],
			modalId: 100,
		};
		this.bsModalRef = this.modalService.show(YoutubeComponent, {
			initialState,
			class: "modal-lg",
			ignoreBackdropClick: true,
			backdrop: true,
			id: 100,
		});
	}

	openIframe(offerId: number, iframeId: string) {
		const initialState = {
			list: { place: offerId, iframe: iframeId },
			modalId: 101,
		};
		this.bsModalRef = this.modalService.show(IframeModalComponent, {
			initialState,
			class: "modal-xl modal-dialog-centered",
			ignoreBackdropClick: true,
			backdrop: true,
			id: 101,
		});
	}

	closeModal(modId?: number) {
		// this.modalService.hide(modId);
		this.modalService.hide();
	}

	openOffers(id: number) {
		this.urbo
			.getOffers(id)
			.pipe(takeUntil(this.unscubscribe))
			.subscribe({
				next: (data: any) => {
					if (data.data.length > 0) {
						this.offers = data.data;
						// console.log(this.offers);
					}
				},
				error: (err) => (this.errorMessage = err),
			});
	}

	getSpecialOffer(id: number) {
		// console.log(id)
		this.urbo
			.getSpecialOffers(id)
			.pipe(takeUntil(this.unscubscribe))
			.subscribe({
				next: (data: any) => {
					if (data.data.length > 0) {
						this.specailOffer = data.data[0];
					}
				},
				error: (err) => (this.errorMessage = err),
			});
	}

	ngOnDestroy() {
		this.unscubscribe.next();
		this.unscubscribe.complete();
	}
}
