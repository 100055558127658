<div class="modal-header border-bottom-0 py-4">
    <img src="../../../assets/images/logos/expo-summer-logo-white-w125.png">
    <app-langswitch class="mt-n5"></app-langswitch>
  </div>
  <div class="modal-body">
    <h5 class="text-white text-uppercase">{{'auth.getCodeFromMail' | translate}}</h5>
    <form>
      <div class="form-floating mb-1">
        <input type="text" name="code" class="form-control rounded-0 border-0" [(ngModel)]="code" required [placeholder]="'auth.code' | translate" >
        <label for="code">{{'auth.code' | translate}}</label>
      </div>
      <div class="form-floating mb-1">
        <input type="email" name="email" class="form-control rounded-0 border-0" [(ngModel)]="email" required [placeholder]="'auth.email' | translate" #emailField="ngModel">
        <label for="email">{{'auth.email' | translate}}</label>
      </div>
      <div class="form-floating mb-1">
        <input type="password" name="password" class="form-control rounded-0 border-0" [(ngModel)]="password" required id="password" [placeholder]="'auth.password' | translate">
        <label for="password">{{'auth.password' | translate}}</label>
      </div>
      <div class="invalid-feedback d-block badge bg-danger" *ngIf="!isValid">
        {{this.errorMessage}}
      </div>
    </form>
  </div>
  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-outline-light" (click)="registration()">{{'auth.registration' | translate}}</button>
    <button type="button" class="btn btn-info text-uppercase" (click)="signIn()">{{'auth.login' | translate}}</button>
    <button type="button" class="btn btn-secondary text-uppercase ms-auto" (click)="completeNewPassword()">{{'auth.changePassword' | translate}}</button>
  </div>