import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
declare var gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLoaded: boolean = false;
  constructor(public router: Router) {
    router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe((e: any) => {
      gtag('config', 'G-1DHRDXP7FC', { 'page_path': e.urlAfterRedirects });
      gtag('config', 'AW-702632032', { 'page_path': e.urlAfterRedirects });
      gtag('config', 'UA-184052987-1', { 'page_path': e.urlAfterRedirects });
    });
  }


  ngOnInit(): void {
    this.router.events.subscribe(
      event => {
        if (event instanceof NavigationStart) {
          this.isLoaded = true;
        }
        else if (event instanceof NavigationEnd) {
          this.isLoaded = false;
        }
      },
      error => {
        this.isLoaded = false;
        console.log(error);
      })
      
  }

}
