import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { FormsModule } from '@angular/forms'; 
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './logintmp.component.html',
  styleUrls: ['./logintmp.component.scss']
})
export class LogintmpComponent implements OnInit {

  email: string = '';
  password: string = '';

  constructor(private router: Router,  public auth: AuthService,) { }

  ngOnInit(): void {
  }
  async loginWithCognito() {
    try {
      var user = await Auth.signIn(this.email.toString(), this.password.toString());
      // console.log('Authentication performed for user=' + this.email + 'password=' + this.password + ' login result==' + user);
      var tokens = user.signInUserSession;
      if (tokens != null) {
        console.log('User authenticated');

        this.router.navigate(['home']);
        alert('You are logged in successfully !');

      }
    } catch (error) {
      console.log(error);
      alert('User Authentication failed');
    }
  }


  deleteUser() {
    this.auth.deleteUser();
  }

  signOut() {
    Auth.signOut();
  }

}
