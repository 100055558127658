<div class="modal-header border-bottom-0 py-4">
    <img src="../../../assets/images/logos/expo-summer-logo-white-w125.png">
    <button type="button" class="btn-close btn-close-white align-self-start" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
</div>
<div class="modal-body">
    <dl class="row text-white">
        <dt class="col-sm-2">{{'auth.name' | translate}}:</dt>
        <dd class="col-sm-10">
            <h4>{{visitor?.givenName}} {{visitor?.familyName}}</h4>
        </dd>

        <dt class="col-sm-2">{{'auth.position' | translate}}:</dt>
        <dd class="col-sm-10">
            <h4>{{visitor?.position}}</h4>
        </dd>

        <dt class="col-sm-2">{{'auth.company' | translate}}:</dt>
        <dd class="col-sm-10">
            <h4>{{visitor?.company}}</h4>
        </dd>
        <dt class="col-sm-2" *ngIf="visitor?.city">{{'auth.city' | translate}}:</dt>
        <dd class="col-sm-10" *ngIf="visitor?.city">
            <h4>{{visitor?.city}}</h4>
        </dd>
    </dl>
    <div class="mb-3">
        <div class="form-floating">
            <textarea [(ngModel)]="message" class="form-control" placeholder="{{'pages.meetingArea.message' | translate}}" id="textMessage" style="height: 100px"></textarea>
            <label for="textMessage">{{'pages.meetingArea.message' | translate}}</label>
        </div>
    </div>
    <div class="alert alert-secondary" *ngIf="isSent">{{'pages.meetingArea.messageIsSent' | translate}}</div>
</div>
<div class="modal-footer border-top-0">
    <button class="btn btn-outline-light align-self-end"  (click)="closeModal()">{{'misc.close' | translate}}</button>
    <button class="btn btn-secondary align-self-end" [disabled]="isSent" (click)="sendContact(message)">{{'misc.send' | translate}}</button>
</div>
