import { AfterContentInit, Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Visitor } from 'src/app/core/cockpit/visitor';
import { Categories } from 'src/app/core/urbo/categories';
import { UrboService } from 'src/app/core/urbo/urbo.service';
import { VisitorsModalComponent } from '../visitors-modal/visitors-modal.component';

@Component({
	selector: 'app-visitors',
	templateUrl: './visitors.component.html',
	styleUrls: ['./visitors.component.scss']
})
export class VisitorsComponent implements OnInit {
	errorMessage: any;

	constructor(
		private modalService: BsModalService,
		private urbo: UrboService
	) { }

	bsModalRef!: BsModalRef;


	@Input() visitors!: Visitor[];
	categories!: Categories[];
	visitorData!: Visitor[];
	@Input() query!: string;

	public openModal(id: any) {

		const initialState = {
			id: id
		};
		this.bsModalRef = this.modalService.show(VisitorsModalComponent, {
			class: "modal-lg",
			// ignoreBackdropClick: false,
			// backdrop: true,
			// id: id,
			initialState
		});
	}

	ngOnInit() {}
}
