<footer class="footer mt-auto py-3 px-4 bg-primary">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-5">
                <img src="../../../assets/images/mobile/primexpo-logo.png" class="img-fluid">
            </div>
            <div class="col-5 text-end">
                <img src="../../../assets/images/mobile/urbo-logo.png" class="img-fluid">
            </div>
        </div>
    </div>
</footer>