import { Injectable } from '@angular/core';
import { Email } from './email';
const mailchimp = require("@mailchimp/mailchimp_transactional")(
  "wFpJptsKtPzJ_smjL16aMQ"
);
@Injectable({
  providedIn: 'root'
})
export class MailchimpService {

  constructor() { }

  async send(message: Email) {
    const response = await mailchimp.messages.send({
      message
    });
    // console.log(response);
  }
}
