import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/auth/auth.service';
import { CockpitService } from 'src/app/core/cockpit/cockpit.service';
import { Game } from 'src/app/core/cockpit/game';
import { GameModalComponent } from './game-modal/game-modal.component';

@Component({
	selector: 'app-virtual-cafe',
	templateUrl: './virtual-cafe.component.html',
	styleUrls: ['./virtual-cafe.component.scss']
})
export class VirtualCafeComponent implements OnInit {
	emailExists!: boolean;
	currentVisitor!: any;
	errorMessage: any;
	bsModalRef!: BsModalRef;
	message!: string;

	constructor(
		public translate: TranslateService,
		private modalService: BsModalService,
		private cockpit: CockpitService,
		private auth: AuthService,
	) { }




	ngOnInit(): void {
		this.getCurrentUser();
	}

	async getCurrentUser() {
		this.auth.getCurrentUser().then(user => {
			this.currentVisitor = user.attributes;
			// console.log(this.currentVisitor);

		}).catch(err => {
			console.log(err)
		});
	}

	joinGame() {
		this.cockpit.checkEmail(this.currentVisitor.email).subscribe({
			next: (cats: any) => {
				// console.log(cats)
				if (cats.length > 0) {
					this.emailExists = true;
					this.translate.get('pages.virtualCafe.hasPlayed').subscribe((res: string) => { this.message = res; });
					this.openModal(this.message);
				}
				if (!this.emailExists) {
					this.saveMember(this.currentVisitor);
				}
			},
			error: (err) => (this.errorMessage = err),
		});
		return true;
	}

	saveMember(member: Game) {
		this.cockpit.savePlayer(member).subscribe({
			next: (entry: any) => {
				this.translate.get('pages.virtualCafe.play').subscribe((res: string) => { this.message = res; });
				this.openModal(this.message)
			},
			error: (err) => (this.errorMessage = err),
		});
	}

	openModal(message: string) {
		const initialState = {
			content: message,
		};
		this.bsModalRef = this.modalService.show(GameModalComponent, {
			initialState,
			class: "modal modal-md modal-dialog-centered",
			id: 200
		});
	}

}
