import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Auth } from 'aws-amplify';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CompleteComponent } from '../complete/complete.component';
import { SigninComponent } from '../signin/signin.component';
import { SignupComponent } from '../signup/signup.component';

@Component({
	selector: 'app-forgotten',
	templateUrl: './forgotten.component.html',
	styleUrls: ['./forgotten.component.scss']
})
export class ForgottenComponent implements OnInit {

	email: string = '';
	password: string = '';
	isValid: boolean = true;
	errorMessage: string = '';
	codeSent: boolean = false;

	constructor(
		public bsModalRef: BsModalRef,
		private router: Router,
		private modalService: BsModalService,
		public translate: TranslateService,
	) { }

	forgotPassword() {
		Auth.forgotPassword(this.email.toString())
			.then(data => {
				this.isValid = true;
				this.bsModalRef.hide();
				this.bsModalRef = this.modalService.show(CompleteComponent, { class: "modal-md modal-dialog-centered", backdrop: "static" });
			})
			.catch(error => {
				this.isValid = false;
				console.log(error);
				if (error == 'AuthError: Confirmation code cannot be empty') {
					this.translate.get('auth.emptyCode').subscribe((res: string) => {
						this.errorMessage = res;
					});
				} else if (error == 'AuthError: Username cannot be empty') {
					this.translate.get('auth.emptyUser').subscribe((res: string) => {
						this.errorMessage = res;
					});
				} else if (error == 'AuthError: Password cannot be empty') {
					this.translate.get('auth.emptyPassword').subscribe((res: string) => {
						this.errorMessage = res;
					});
				} else if (error.code) {
					// this.errorMessage = error.code;
					switch (error.message) {
					  case "Attempt limit exceeded, please try after some time.":
						this.translate.get('auth.limitExceeded').subscribe((res: string) => {
						  this.errorMessage = res;
						});
						console.log(error.message);
						break;
					  case "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
						this.translate.get('auth.longerPass').subscribe((res: string) => {
						  this.errorMessage = res;
						});
						console.log(error.message);
						break;
					  case 'AuthError - Username cannot be empty':
						this.translate.get('auth.emptyUser').subscribe((res: string) => {
						  this.errorMessage = res;
						});
						console.log(error.message);
						break;
					  case 'Invalid verification code provided, please try again.':
						this.translate.get('auth.wrongCode').subscribe((res: string) => {
						  this.errorMessage = res;
						});
						console.log(error.message);
						break;
					  // case 'Password did not conform with policy: Password not long enough':
					  //   console.log(error.message);
					  //   break;
					  // case 'User is not confirmed.':
					  //   this.translate.get('auth.notConfirmed').subscribe((res: string) => {
					  // 	this.errorMessage = res;
					  //   });
					  //   console.log(error.message);
					  //   break;
					  // case 'Incorrect username or password.':
					  //   console.log(error.message);
					  //   this.translate.get('auth.incorrect').subscribe((res: string) => {
					  // 	this.errorMessage = res;
					  //   });
					  //   break;
					  // case 'User does not exist.':
					  //   console.log(error.message);
					  //   this.translate.get('auth.notExists').subscribe((res: string) => {
					  // 	this.errorMessage = res;
					  //   });
					  //   break;
					  default:
						this.translate.get('auth.checkFields').subscribe((res: string) => {
						  this.errorMessage = res;
						});
						console.log(error.message);
					}
				}
			});
	}

	// completeNewPassword() {
	//   Auth.forgotPasswordSubmit(username, code, new_password)
	//   .then(data => console.log(data))
	//   .catch(err => console.log(err));
	// }

	registration() {
		this.bsModalRef.hide();
		this.bsModalRef = this.modalService.show(SignupComponent, { class: "modal-md modal-dialog-centered", backdrop: "static" });
	}

	signIn() {
		this.bsModalRef.hide();
		this.bsModalRef = this.modalService.show(SigninComponent, { class: "modal-md modal-dialog-centered", backdrop: "static" });
	}

	ngOnInit(): void {

	}
}
