import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Game } from './game';
import { FullVisitor, Visitor } from './visitor';

@Injectable({
  providedIn: 'root'
})


export class CockpitService {
  

  constructor(private http: HttpClient) { }


  private APIgetVisitors = `${environment.apiUrl}api/collections/get/visitors?token=${environment.apiToken}`;
  private APIsaveVisitor = `${environment.apiUrl}api/collections/save/visitors?token=${environment.apiToken}`;
  private APIgetPlayer = `${environment.apiUrl}api/collections/get/game?token=${environment.apiToken}`;
  private APIsavePlayer = `${environment.apiUrl}api/collections/save/game?token=${environment.apiToken}`;


  
  
  
  saveVisitor(data: FullVisitor): Observable<FullVisitor> {
    // console.log(data);
    return this.http.post<any>(this.APIsaveVisitor, { 
      data: data
    }).pipe(
      catchError(this.handleError)
    )
  }

  getVisitors(): Observable<Visitor> {
    return this.http.post<any>(this.APIgetVisitors, { 
      fields: {
        givenName: 1,
        familyName: 1,
        company: 1,
        position: 1,
        businessType: 1,
        city: 1
      },
      simple: 1
    }).pipe(
      catchError(this.handleError)
    )
  }

  getVisitor(id: any): Observable<Visitor> {
    return this.http.post<any>(this.APIgetVisitors, { 
      simple: 1
    }).pipe(
      map(visitor => visitor.filter((e :any) => e._id === id)),
      catchError(this.handleError)
    )
  }

  checkEmail(email: string): Observable<Game> {
    return this.http.post<any>(this.APIgetPlayer, '').pipe(
      map(players => players.entries.filter((e :any) => e.email === email)),
      catchError(this.handleError)
    )
  }
  savePlayer(data: Game): Observable<Game> {
    // console.log(data);
    return this.http.post<any>(this.APIsavePlayer, { 
      data: data
    }).pipe(
      catchError(this.handleError)
    )
  }

  // save(data: Game): Observable<Game> {
  //   return this.http.post<any>(this.APIsave, { 
  //     data: data
  //   }).pipe(
  //     catchError(this.handleError)
  //   )
  // }

  private handleError( err: HttpErrorResponse) {
    let errorMessage = '';

    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occure: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
  
}
