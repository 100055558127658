<div id="bg" class="d-lg-block d-none">
    <img src="../../../assets/images/bg/virtual-cafe.jpg" class="full-screen" [alt]="'pages.virtualCafe.title' | translate" />
</div>
<div class="static-modal-wrapper d-flex flex-column vh-100">
    <div class="container-xl ms-lg-0 ms-xxl-auto my-auto">
        <div class="row py-4 py-lg-0">
            <div class="col-xl-4 d-flex">
                <div class="modal-dialog my-auto modal-md w-100">
                    <div class="modal-content bg-black">
                        <div class="modal-header border-bottom-0 py-4 ps-4">
                            <img src="../../../assets/images/logos/expo-summer-logo-white-w125.png">
                        </div>
                        <div class="modal-body px-4 pb-4 pt-0">
                            <h1 class="text-white text-uppercase text-nowrap">{{'pages.virtualCafe.title' | translate}} <img src="../../../assets/images/icons/coffee-icon.png" class="align-bottom" style="width: 75px;"></h1>
                            <p class="text-white">{{'pages.virtualCafe.joinDescription' | translate}}</p>
                            <h3 class="text-white">{{'pages.virtualCafe.joinRaffle' | translate}}</h3>
                            <div class="text-center py-4">
                                <button type="button" class="btn btn-info btn-lg text-uppercase" (click)="joinGame()">{{'pages.virtualCafe.join' | translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-8 mt-4 mt-xl-0">
                <div class="row position-relative">
                    <div class="col-md-4 col-6 mb-4">
                        <a href="https://www.facebook.com/floralcosmetic/" target="_blank">
                            <img src="../../../assets/images/coffeearea/ArtS.png" class="img-fluid">
                        </a>
                    </div>
                    <div class="col-md-4 col-6 mb-4">
                        <a href="https://holidayfair-sofia.com/" target="_blank">
                            <img src="../../../assets/images/coffeearea/Holiday_800x533px-1.png" class="img-fluid">
                        </a>
                    </div>
                    <div class="col-md-4 col-6 mb-4">
                        <a href="https://media35.bg/" target="_blank">
                            <img src="../../../assets/images/coffeearea/Media35_800x533px-1.png" class="img-fluid">
                        </a>
                    </div>
                    <div class="col-md-4 col-6 mb-4">
                        <a href="https://primdesign.com/" target="_blank">
                            <img src="../../../assets/images/coffeearea/Prim.png" class="img-fluid">
                        </a>
                    </div>
                    <div class="col-md-4 col-6 mb-4">
                        <a href="https://sofest.avgroup-bulgaria.com/" target="_blank">
                            <img src="../../../assets/images/coffeearea/SoFest_800x533px-1.png" class="img-fluid">
                        </a>
                    </div>
                    <div class="col-md-4 col-6 mb-4">
                        <a href="https://welcome.bg/" target="_blank">
                            <img src="../../../assets/images/coffeearea/Welcome_800x533px.png" class="img-fluid">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
