import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Auth } from 'aws-amplify';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ForgottenComponent } from '../forgotten/forgotten.component';
import { SignupComponent } from '../signup/signup.component';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  email: string = '';
  password: string = '';
  isValid: boolean = true;
  errorMessage: string = '';
  
  constructor(
    public bsModalRef: BsModalRef,
    private router: Router,
    private modalService: BsModalService,
    public translate: TranslateService,
  ) { }

  async loginWithCognito() {
    try {
      var user = await Auth.signIn(this.email.toString(), this.password.toString());
      var tokens = user.signInUserSession;
      if (tokens != null) {
        // console.log('User authenticated');
        this.isValid = true;
        this.router.navigate(['lobby']);
        this.bsModalRef.hide();
        
      }
    } catch (error) {
      this.isValid = false;
      switch (error.message) {
        case 'Username should be either an email or a phone number.':
          this.translate.get('auth.emptyUser').subscribe((res: string) => {
            this.errorMessage = res;
          });
          console.log(error.message);
          break;
        case 'Password did not conform with policy: Password not long enough':
          console.log(error.message);
          break;
        case 'User is not confirmed.':
          this.translate.get('auth.notConfirmed').subscribe((res: string) => {
            this.errorMessage = res;
          });
          console.log(error.message);
          break;
        case 'Incorrect username or password.':
          console.log(error.message);
          this.translate.get('auth.incorrect').subscribe((res: string) => {
            this.errorMessage = res;
          });
          break;
        case 'User does not exist.':
          console.log(error.message);
          this.translate.get('auth.notExists').subscribe((res: string) => {
            this.errorMessage = res;
          });
          break;
        default:
          this.translate.get('auth.emptyUser').subscribe((res: string) => {
            this.errorMessage = res;
          });
          console.log(error.message);
      }
    }
  }

  registration(){
    this.bsModalRef.hide();
    this.bsModalRef = this.modalService.show(SignupComponent, { class: "modal-md modal-dialog-centered", backdrop: "static" });
  }

  forgottenPassword() {
    this.bsModalRef.hide();
    this.bsModalRef = this.modalService.show(ForgottenComponent, { class: "modal-md modal-dialog-centered", backdrop: "static" });
  }
  
  ngOnInit(): void {
   
  }

}
