import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardGuard } from './auth/auth-guard.guard';
import { EventHallComponent } from './pages/event-hall/event-hall.component';
import { ExpoHall1Component } from './pages/expo-hall1/expo-hall1.component';
import { ExpoHall2Component } from './pages/expo-hall2/expo-hall2.component';
import { HomeComponent } from './pages/home/home.component';
import { LobbyComponent } from './pages/lobby/lobby.component';
import { MeetingAreaComponent } from './pages/meeting-area/meeting-area.component';
import { VirtualCafeComponent } from './pages/virtual-cafe/virtual-cafe.component';
import { LogintmpComponent } from './temp/login/logintmp.component';
import { SignUptmpComponent } from './temp/sign-up/sign-uptmp.component';

const routes: Routes = [
  { path: 'login', component: LogintmpComponent },
  { path: 'sign-up', component: SignUptmpComponent },
  { path: 'lobby', component: LobbyComponent, canActivate: [AuthGuardGuard]  },
  { path: 'expo-hall-1', component: ExpoHall1Component, canActivate: [AuthGuardGuard]  },
  { path: 'expo-hall-2', component: ExpoHall2Component, canActivate: [AuthGuardGuard]  },
  { path: 'event-hall', component: EventHallComponent, canActivate: [AuthGuardGuard]  },
  { path: 'meeting-area', component: MeetingAreaComponent, canActivate: [AuthGuardGuard]  },
  { path: 'virtual-cafe', component: VirtualCafeComponent, canActivate: [AuthGuardGuard]  },
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
