<!-- <div class="desktop d-lg-block d-none"> -->
<div id="bg" class="d-lg-block d-none">
    <img src="../../../assets/images/bg/event-hall.jpg" class="full-screen" [alt]="'pages.eventHall.title' | translate" />
</div>
<div class="static-modal-wrapper d-flex flex-column min-vh-100">
    <div class="modal-dialog my-lg-auto modal-xl mt-4">
        <div class="modal-content opacity-9">
            <div class="modal-header border-bottom-0 py-4">
                <img src="../../../assets/images/logos/expo-summer-logo-white-w125.png">
                <h1 class="text-white text-uppercase">{{'pages.meetingArea.title' | translate}}</h1>
            </div>
            <div class="modal-body p-4">
                <div class="row">
                    <div class="col-lg-6">
                        <h2 class="text-white text-uppercase">{{'pages.meetingArea.bizCardExchange' | translate}}</h2>
                        <div class="form-group mb-1">

                            <div class="input-group mb-3">
                                <input type="text" name="searchVis" class="form-control border-0" placeholder="{{'pages.meetingArea.search' | translate}}" [(ngModel)]="query" #searchVisitors>
                                <select (change)="filterItemsByCategory($event)" name="businessType" class="form-select border-0 border-start" id="floatingSelect" aria-label="Floating label select example">
                                    <option selected value="-1">{{'auth.all' | translate}}</option>
                                    <option *ngFor="let types of categories" [value]="types.id">{{types.name}}</option>
                                    <option value="0">{{'auth.types.other' | translate}}</option>
                                </select>
                            </div>
                        </div>
                        <p class="text-white">{{'pages.meetingArea.searchVisitorParagraph' | translate}}</p>
                        <app-visitors [visitors]="filteredVisitors" [query]="query" class="col-xl-3 col-lg-4 col-md-3 col-6"></app-visitors>
                    </div>
                    <div class="col-lg-6">
                        <h2 class="text-white text-uppercase my-5 my-lg-0">{{'pages.meetingArea.scheduleMeeting' | translate}}</h2>
                        <div class="row max-vh-60 overflow-auto custom-scroll px-4 pb-4">
                            <div *ngFor="let item of meetings" class="col-lg-4 col-md-6 mt-3">
                                <a [routerLink]="" (click)="getPlaceContacts(item!.place_id)" class="text-decoration-none stretched-link-">
                                    <img [src]="item?.image" class="rounded-sm img-fluid" />
                                    <h6 class="text-white mt-2">{{item?.title}}</h6>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="mobile d-flex flex-column d-lg-none">
    <app-footer></app-footer>
</div>
<!-- </div>
<div class="mobile d-block d-lg-none">
    eedede
</div> -->
