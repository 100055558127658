import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Imagemap } from 'src/app/core/imagemap/imagemap';
import { ImageScheme } from 'src/app/core/mobile-images/image-scheme';

@Component({
  selector: 'app-lobby',
  templateUrl: './lobby.component.html',
  styleUrls: ['./lobby.component.scss']
})
export class LobbyComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    private titleService: Title
  ) { }

  data: Imagemap = {
    title: "pages.lobby.title",
    xxl: {
      image: "../../../assets/images/bg/lobby-xxl.jpg",
      coords: [
        {
          tooltip: "pages.expoHall1.title",
          coords: "581,426,827,475",
          shape: "rect",
          action: "goToPage()",
          actionParam: "expo-hall-1"
        },
        {
          tooltip: "pages.expoHall2.title",
          coords: "581,529,827,480", 
          shape: "rect",
          action: "goToPage()",
          actionParam: "expo-hall-2"
        },
        {
          tooltip: "pages.meetingArea.title", 
          coords: "1185,502,1431,453", 
          shape: "rect",
          action: "goToPage()",
          actionParam: "meeting-area"
        },
        {
          tooltip: "pages.eventHall.title", 
          coords: "1436,448,1185,395", 
          shape: "rect",
          action: "goToPage()",
          actionParam: "event-hall"
        },
        {
          tooltip: "pages.travelMarket.title", 
          coords: "1186,507,1431,563", 
          shape: "rect",
          action: "openIFrameModal()",
          actionParam: "GhompKDFD6tXAT7U0Fmf3ZyYaff753O8"
        },
        {
          tooltip: "pages.virtualCafe.title",
          coords: "173,612,72",
          shape: "circle",
          action: "goToPage()",
          actionParam: "virtual-cafe"
        },
        {
          tooltip: "banners.travelnews", 
          coords: "243,524,327,775", 
          shape: "rect",
          action: "goToExtPage()",
          actionParam: "https://travelnews.bg/"
        },
        {
          tooltip: "banners.darik",
          coords: "338,773,338,524,421,526,423,778,413,705,394,666,369,683,359,760", 
          shape: "poly",
          action: "goToExtPage()",
          actionParam: "https://darikradio.bg/"
        },
        {
          tooltip: "banners.bgtourism", 
          coords: "428,524,511,778", 
          shape: "rect",
          action: "goToExtPage()",
          actionParam: "https://bgtourism.bg/"
        },
        {
          tooltip: "banners.ministry", 
          coords: "873,315,1142,527", 
          shape: "rect",
          action: "openModal()",
          actionParam: "3277"
        },
        {
          tooltip: "banners.sunnyDay",
          coords: "593,649,706,844", 
          shape: "rect",
          action: "openModal()",
          actionParam: "3294"
        },
        {
          tooltip: "banners.shumen",
          coords: "1227,646,1334,841", 
          shape: "rect",
          action: "openModal()",
          actionParam: "3290"
        },
        {
          tooltip: "banners.sabre", 
          coords: "1366,665,1492,916", 
          shape: "rect",
          action: "openIFrameModal()",
          actionParam: "UtLwZIwbKLKT8iW5IB8Uoc154DG7Zu5v,8608"
        },
        {
          tooltip: "banners.bubspa",
          coords: "1507,527,1595,777", 
          shape: "rect",
          action: "openModal()",
          actionParam: "3271"
        },
        {
          tooltip: "banners.bhra",
          coords: "1604,524,1692,777", 
          shape: "rect",
          action: "goToExtPage()",
          actionParam: "https://www.bhra-bg.org/"
        },
        {
          tooltip: "banners.asprovalta",
          coords: "259,314,372,515", 
          shape: "rect",
          action: "openModal()",
          actionParam: "3303"
        },
        {
          tooltip: "banners.varna",
          coords: "388,314,500,519", 
          shape: "rect",
          action: "openModal()",
          actionParam: "3280"
        },
        {
          tooltip: "banners.sabreVideo", 
          coords: "354,178,552,290", 
          shape: "rect",
          action: "openVideoModal()",
          actionParam: "z5-TtGENtlo"
        },
        {
          tooltip: "pages.home.video1", 
          coords: "1210,175,1407,293", 
          shape: "rect",
          action: "openVideoModal()",
          actionParam: "s8hnSJ9p6NU"
        },
        {
          tooltip: "banners.evrokomVideo", 
          coords: "1463,176,1660,295", 
          shape: "rect",
          action: "openVideoModal()",
          actionParam: "c7o_wLIufc8"
        },
        {
          tooltip: "pages.home.video2", 
          coords: "605,180,805,292", 
          shape: "rect",
          action: "openVideoModal()",
          actionParam: "2gGJUzjWRz0"
        },
      ]
    },
    xl: {
      image: "../../../assets/images/bg/lobby-xl.jpg",
      coords: [
        {
          tooltip: "pages.expoHall1.title",
          coords: "464,337,664,380",
          shape: "rect",
          action: "goToPage()",
          actionParam: "expo-hall-1"
        },
        {
          tooltip: "pages.expoHall2.title",
          coords: "466,384,663,425", 
          shape: "rect",
          action: "goToPage()",
          actionParam: "expo-hall-2"
        },
        {
          tooltip: "pages.meetingArea.title", 
          coords: "949,362,1146,406", 
          shape: "rect",
          action: "goToPage()",
          actionParam: "meeting-area"
        },
        {
          tooltip: "pages.eventHall.title", 
          coords: "949,319,1146,359", 
          shape: "rect",
          action: "goToPage()",
          actionParam: "event-hall"
        },
        {
          tooltip: "pages.travelMarket.title", 
          coords: "948,410,1147,453", 
          shape: "rect",
          action: "openIFrameModal()",
          actionParam: "GhompKDFD6tXAT7U0Fmf3ZyYaff753O8"
        },
        {
          tooltip: "pages.virtualCafe.title",
          coords: "137,489,56",
          shape: "circle",
          action: "goToPage()",
          actionParam: "virtual-cafe"
        },
        {
          tooltip: "banners.travelnews", 
          coords: "196,420,265,623", 
          shape: "rect",
          action: "goToExtPage()",
          actionParam: "https://travelnews.bg/"
        },
        {
          tooltip: "banners.darik",
          coords: "269,618,271,422,339,422,340,620,307,536", 
          shape: "poly",
          action: "goToExtPage()",
          actionParam: "https://darikradio.bg/"
        },
        {
          tooltip: "banners.bgtourism", 
          coords: "345,422,412,624", 
          shape: "rect",
          action: "goToExtPage()",
          actionParam: "https://bgtourism.bg/"
        },
        {
          tooltip: "banners.ministry", 
          coords: "698,251,914,423", 
          shape: "rect",
          action: "openModal()",
          actionParam: "3277"
        },
        {
          tooltip: "banners.sunnyDay",
          coords: "476,521,567,677", 
          shape: "rect",
          action: "openModal()",
          actionParam: "3294"
        },
        {
          tooltip: "banners.shumen",
          coords: "983,517,1067,674", 
          shape: "rect",
          action: "openModal()",
          actionParam: "3290"
        },
        {
          tooltip: "banners.sabre", 
          coords: "1094,533,1196,735", 
          shape: "rect",
          action: "openIFrameModal()",
          actionParam: "UtLwZIwbKLKT8iW5IB8Uoc154DG7Zu5v,8608"
        },
        {
          tooltip: "banners.bubspa",
          coords: "1204,420,1279,620", 
          shape: "rect",
          action: "openModal()",
          actionParam: "3271"
        },
        {
          tooltip: "banners.bhra",
          coords: "1283,423,1358,621", 
          shape: "rect",
          action: "goToExtPage()",
          actionParam: "https://www.bhra-bg.org/"
        },
        {
          tooltip: "banners.asprovalta",
          coords: "208,254,295,412", 
          shape: "rect",
          action: "openModal()",
          actionParam: "3303"
        },
        {
          tooltip: "banners.varna",
          coords: "310,252,401,414", 
          shape: "rect",
          action: "openModal()",
          actionParam: "3280"
        },
        {
          tooltip: "banners.sabreVideo", 
          coords: "280,141,442,233", 
          shape: "rect",
          action: "openVideoModal()",
          actionParam: "z5-TtGENtlo"
        },
        {
          tooltip: "pages.home.video1", 
          coords: "485,140,645,233", 
          shape: "rect",
          action: "openVideoModal()",
          actionParam: "s8hnSJ9p6NU"
        },
        {
          tooltip: "banners.evrokomVideo", 
          coords: "1170,144,1328,233", 
          shape: "rect",
          action: "openVideoModal()",
          actionParam: "c7o_wLIufc8"
        },
        {
          tooltip: "pages.home.video2", 
          coords: "967,142,1127,233", 
          shape: "rect",
          action: "openVideoModal()",
          actionParam: "2gGJUzjWRz0"
        },
      ]
    },
    lg: {
      image: "../../../assets/images/bg/lobby-lg.jpg",
      coords: [
        {
          tooltip: "pages.expoHall1.title",
          coords: "437,317,618,354",
          shape: "rect",
          action: "goToPage()",
          actionParam: "expo-hall-1"
        },
        {
          tooltip: "pages.expoHall2.title",
          coords: "436,359,621,398", 
          shape: "rect",
          action: "goToPage()",
          actionParam: "expo-hall-2"
        },
        {
          tooltip: "pages.meetingArea.title", 
          coords: "889,340,1076,376", 
          shape: "rect",
          action: "goToPage()",
          actionParam: "meeting-area"
        },
        {
          tooltip: "pages.eventHall.title", 
          coords: "891,298,1078,337", 
          shape: "rect",
          action: "goToPage()",
          actionParam: "event-hall"
        },
        {
          tooltip: "pages.travelMarket.title", 
          coords: "887,379,1074,423", 
          shape: "rect",
          action: "openIFrameModal()",
          actionParam: "GhompKDFD6tXAT7U0Fmf3ZyYaff753O8"
        },
        {
          tooltip: "pages.virtualCafe.title",
          coords: "128,459,52",
          shape: "circle",
          action: "goToPage()",
          actionParam: "virtual-cafe"
        },
        {
          tooltip: "banners.travelnews", 
          coords: "183,393,245,584", 
          shape: "rect",
          action: "goToExtPage()",
          actionParam: "https://travelnews.bg/"
        },
        {
          tooltip: "banners.darik",
          coords: "253,581,253,395,316,395,315,582,287,500", 
          shape: "poly",
          action: "goToExtPage()",
          actionParam: "https://darikradio.bg/"
        },
        {
          tooltip: "banners.bgtourism", 
          coords: "322,390,385,582", 
          shape: "rect",
          action: "goToExtPage()",
          actionParam: "https://bgtourism.bg/"
        },
        {
          tooltip: "banners.ministry", 
          coords: "654,235,858,397", 
          shape: "rect",
          action: "openModal()",
          actionParam: "3277"
        },
        {
          tooltip: "banners.sunnyDay",
          coords: "446,490,531,635", 
          shape: "rect",
          action: "openModal()",
          actionParam: "3294"
        },
        {
          tooltip: "banners.shumen",
          coords: "923,483,1000,631", 
          shape: "rect",
          action: "openModal()",
          actionParam: "3290"
        },
        {
          tooltip: "banners.sabre", 
          coords: "1025,501,1123,688", 
          shape: "rect",
          action: "openIFrameModal()",
          actionParam: "UtLwZIwbKLKT8iW5IB8Uoc154DG7Zu5v,8608"
        },
        {
          tooltip: "banners.bubspa",
          coords: "1132,393,1198,581", 
          shape: "rect",
          action: "openModal()",
          actionParam: "3271"
        },
        {
          tooltip: "banners.bhra",
          coords: "1203,395,1269,581", 
          shape: "rect",
          action: "goToExtPage()",
          actionParam: "https://www.bhra-bg.org/"
        },
        {
          tooltip: "banners.asprovalta",
          coords: "194,238,278,389", 
          shape: "rect",
          action: "openModal()",
          actionParam: "3303"
        },
        {
          tooltip: "banners.varna",
          coords: "292,234,376,385", 
          shape: "rect",
          action: "openModal()",
          actionParam: "3280"
        },
        {
          tooltip: "banners.sabreVideo", 
          coords: "265,136,413,219", 
          shape: "rect",
          action: "openVideoModal()",
          actionParam: "z5-TtGENtlo"
        },
        {
          tooltip: "pages.home.video1", 
          coords: "455,134,604,219", 
          shape: "rect",
          action: "openVideoModal()",
          actionParam: "s8hnSJ9p6NU"
        },
        {
          tooltip: "banners.evrokomVideo", 
          coords: "1100,136,1247,220", 
          shape: "rect",
          action: "openVideoModal()",
          actionParam: "c7o_wLIufc8"
        },
        {
          tooltip: "pages.home.video2", 
          coords: "906,134,1054,220", 
          shape: "rect",
          action: "openVideoModal()",
          actionParam: "2gGJUzjWRz0"
        },
      ]
    },
    md: {
      image: "../../../assets/images/bg/lobby-md.jpg",
      coords: [
        {
          tooltip: "pages.expoHall1.title",
          coords: "414,299,588,336",
          shape: "rect",
          action: "goToPage()",
          actionParam: "expo-hall-1"
        },
        {
          tooltip: "pages.expoHall2.title",
          coords: "413,342,588,375", 
          shape: "rect",
          action: "goToPage()",
          actionParam: "expo-hall-2"
        },
        {
          tooltip: "pages.meetingArea.title", 
          coords: "843,322,1018,359", 
          shape: "rect",
          action: "goToPage()",
          actionParam: "meeting-area"
        },
        {
          tooltip: "pages.eventHall.title", 
          coords: "842,282,1020,318", 
          shape: "rect",
          action: "goToPage()",
          actionParam: "event-hall"
        },
        {
          tooltip: "pages.travelMarket.title", 
          coords: "842,363,1020,403", 
          shape: "rect",
          action: "openIFrameModal()",
          actionParam: "GhompKDFD6tXAT7U0Fmf3ZyYaff753O8"
        },
        {
          tooltip: "pages.virtualCafe.title",
          coords: "123,435,50",
          shape: "circle",
          action: "goToPage()",
          actionParam: "virtual-cafe"
        },
        {
          tooltip: "banners.travelnews", 
          coords: "172,374,235,553", 
          shape: "rect",
          action: "goToExtPage()",
          actionParam: "https://travelnews.bg/"
        },
        {
          tooltip: "banners.darik",
          coords: "238,552,239,374,301,373,297,551,272,467",
          shape: "poly",
          action: "goToExtPage()",
          actionParam: "https://darikradio.bg/"
        },
        {
          tooltip: "banners.bgtourism", 
          coords: "302,552,366,374", 
          shape: "rect",
          action: "goToExtPage()",
          actionParam: "https://bgtourism.bg/"
        },
        {
          tooltip: "banners.ministry", 
          coords: "618,223,811,376", 
          shape: "rect",
          action: "openModal()",
          actionParam: "3277"
        },
        {
          tooltip: "banners.sunnyDay",
          coords: "421,462,505,603", 
          shape: "rect",
          action: "openModal()",
          actionParam: "3294"
        },
        {
          tooltip: "banners.shumen",
          coords: "875,459,950,598", 
          shape: "rect",
          action: "openModal()",
          actionParam: "3290"
        },
        {
          tooltip: "banners.sabre", 
          coords: "971,475,1063,652", 
          shape: "rect",
          action: "openIFrameModal()",
          actionParam: "UtLwZIwbKLKT8iW5IB8Uoc154DG7Zu5v,8608"
        },
        {
          tooltip: "banners.bubspa",
          coords: "1073,374,1139,552", 
          shape: "rect",
          action: "openModal()",
          actionParam: "3271"
        },
        {
          tooltip: "banners.bhra",
          coords: "1139,373,1203,552", 
          shape: "rect",
          action: "goToExtPage()",
          actionParam: "https://www.bhra-bg.org/"
        },
        {
          tooltip: "banners.asprovalta",
          coords: "184,226,265,368", 
          shape: "rect",
          action: "openModal()",
          actionParam: "3303"
        },
        {
          tooltip: "banners.varna",
          coords: "275,222,357,368", 
          shape: "rect",
          action: "openModal()",
          actionParam: "3280"
        },
        {
          tooltip: "banners.sabreVideo", 
          coords: "251,125,391,207", 
          shape: "rect",
          action: "openVideoModal()",
          actionParam: "z5-TtGENtlo"
        },
        {
          tooltip: "pages.home.video1", 
          coords: "433,125,570,206", 
          shape: "rect",
          action: "openVideoModal()",
          actionParam: "s8hnSJ9p6NU"
        },
        {
          tooltip: "banners.evrokomVideo", 
          coords: "1040,128,1180,209", 
          shape: "rect",
          action: "openVideoModal()",
          actionParam: "c7o_wLIufc8"
        },
        {
          tooltip: "pages.home.video2", 
          coords: "859,127,1000,207", 
          shape: "rect",
          action: "openVideoModal()",
          actionParam: "2gGJUzjWRz0"
        },
      ],
    }
  }

  mobileImages: ImageScheme[] = [
    {
      image: "../../../assets/images/mobile/bulgaria-banner.png",
      size: "2x",
      tooltip: "banners.ministry",
      action: "openModal()",
      actionParam: "3277"
    },
    {
      image: "../../../assets/images/mobile/bubspa-banner.png",
      size: "1x",
      tooltip: "banners.bubspa",
      action: "openModal()",
      actionParam: "3271"
    },
    {
      image: "../../../assets/images/mobile/bhra-banner.png",
      size: "1x",
      tooltip: "banners.bhra",
      action: "goToExtPage()",
      actionParam: "https://www.bhra-bg.org/"
    },
    {
      image: "../../../assets/images/mobile/sabre-banner.png",
      size: "1x",
      tooltip: "banners.sabre",
      action: "openIFrameModal()",
      actionParam: "UtLwZIwbKLKT8iW5IB8Uoc154DG7Zu5v,8608"
    },
    {
      image: "../../../assets/images/mobile/sunny-day-banner.png",
      size: "1x",
      tooltip: "banners.sunnyDay",
      action: "openModal()",
      actionParam: "3294"
    },
    {
      image: "../../../assets/images/mobile/varna.png",
      size: "2x",
      tooltip: "banners.varna",
      action: "openModal()",
      actionParam: "3280"
    },
    {
      image: "../../../assets/images/mobile/asprovalta.png",
      size: "1x",
      tooltip: "banners.asprovalta",
      action: "openModal()",
      actionParam: "3303"
    },{
      image: "../../../assets/images/mobile/bg-tourism-banner.png",
      size: "1x",
      tooltip: "banners.bgtourism",
      action: "goToExtPage()",
      actionParam: "https://bgtourism.bg/"
    },{
      image: "../../../assets/images/mobile/darik-banner.png",
      size: "1x",
      tooltip: "banners.darik",
      action: "goToExtPage()",
      actionParam: "https://darikradio.bg/"
    },
    {
      image: "../../../assets/images/mobile/travelnews-banner.png",
      size: "1x",
      tooltip: "banners.travelnews",
      action: "goToExtPage()",
      actionParam: "https://travelnews.bg/"
    },
    {
      image: "../../../assets/images/mobile/ek-banner-2x.png",
      size: "2x",
      tooltip: "banners.evrokomVideo",
      action: "openVideoModal()",
      actionParam: "c7o_wLIufc8"
    },
  ]

  bsModalRef!: BsModalRef;


  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }


  ngOnInit(): void {
    this.translate.get(this.data.title).subscribe((res: string) => {
      this.setTitle(res);
    });
  }
  
}
