import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CognitoUser, CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { CockpitService } from 'src/app/core/cockpit/cockpit.service';
import { FullVisitor } from 'src/app/core/cockpit/visitor';
import { Email } from 'src/app/core/mailchimp/email';
import { MailchimpService } from 'src/app/core/mailchimp/mailchimp.service';
import { Categories } from 'src/app/core/urbo/categories';
import { UrboService } from 'src/app/core/urbo/urbo.service';


@Component({
	selector: 'app-visitors-modal',
	templateUrl: './visitors-modal.component.html',
	styleUrls: ['./visitors-modal.component.scss']
})
export class VisitorsModalComponent implements OnInit, OnDestroy {
	errorMessage: any;

	constructor(
		private modalService: BsModalService,
		public translate: TranslateService,
		private cockpit: CockpitService,
		private emailSender: MailchimpService,
		private auth: AuthService,
		private urbo: UrboService,
	) { }

	bsModalRef!: BsModalRef;
	private unscubscribe = new Subject();
	visitor!: FullVisitor;
	categories!: Categories[];
	currentVisitor!: any;
	currentVisitorCategory!: any;
	message: string = '';
	id!: any;
	emailMessage!: Email;
	isSent: boolean = false;

	

	ngOnInit(): void {
		this.getVisitor(this.id)
		this.getCurrentUser();
	}

	async getCurrentUser() {
		this.auth.getCurrentUser().then(user => {
			// console.log(user);
			this.currentVisitor = user.attributes;
			this.getVisitorTypes()

		}).catch(err => {
			console.log(err)
		});
	}

	getVisitor(id: any) {
		this.cockpit
			.getVisitor(id)
			.pipe(takeUntil(this.unscubscribe))
			.subscribe({
				next: (visitor: any) => {
					this.visitor = visitor[0];
					// console.log(visitor)
				},
				error: (err) => (this.errorMessage = err),
			});
	}

	getVisitorTypes() {
		this.urbo
			.getCategories()
			.pipe(takeUntil(this.unscubscribe))
			.subscribe({
				next: (cats: any) => {
					this.categories = cats;
					// console.log(this.categories);
					this.currentVisitorCategory = this.getCategoryName(this.currentVisitor['custom:type_of_business']);
					// console.log(this.currentVisitorCategory)
				},
				error: (err) => (this.errorMessage = err),
			});
	}

	getCategoryName(id: any) {
		let category:any = {};
		if (id == '0') {
			this.translate.get('auth.types.other').subscribe((res: string) => {
				category.name = res;
			});
			return category
		} else {
			return this.categories.find(cat => cat.id == id)
		}
		
	}

	sendContact(message: string) {

		const emailBody: string = `
		Здравейте ${this.visitor.givenName} ${this.visitor.familyName},<br /><br />

		Получихте заявка за обмен на контакти във Holiday & SPA Virtual Show от:<br />
		Име: ${this.currentVisitor.name} ${this.currentVisitor.family_name}<br />
		Компания: ${this.currentVisitor['custom:company']}<br />
		Позиция: ${this.currentVisitor['custom:position']}<br />
		Тип Бизнес: ${this.currentVisitorCategory?.name}<br />
		Телефон: ${this.currentVisitor['custom:phone']}<br />
		E-mail: ${this.currentVisitor.email}<br />
		Град/Държава: ${this.currentVisitor['custom:city']!}<br />
		Съобщение: ${message}<br /><br />
		
		Ако това представлява интерес за вас можете да се свържете чрез един от посочените от заявителя канали!<br /><br />

		С най-добри пожелания,<br />
		Holiday & SPA Virtual Show Team<br /><br />

		====================================<br /><br />

		Hello ${this.visitor.givenName} ${this.visitor.familyName},<br /><br />

		You received a request for exchange of contacts in Holiday & SPA Virtual Show from:<br />
		Name: ${this.currentVisitor.name} ${this.currentVisitor.family_name}<br />
		Company: ${this.currentVisitor['custom:company']}<br />
		Position: ${this.currentVisitor['custom:position']}<br />
		Business Type: ${this.currentVisitorCategory?.name}<br />
		Phone: ${this.currentVisitor['custom:phone']}<br />
		E-mail: ${this.currentVisitor.email}<br />
		City / Country: ${this.currentVisitor['custom:city']!}<br />
		Message: ${message}<br /><br />
		
		If this is of interest to you, you can contact through one of the channels specified by the applicant!<br /><br />

		With best wishes,<br />
		Holiday & SPA Virtual Show Team<br />
		`
		const email: Email = {
			from_email: "no-reply@travelmarket.bg",
			from_name: "Holiday & SPA Virtual Show Team",
			subject: "Biz cards exchange",
			html: emailBody,
			to: [
				{
					email: this.visitor.email,
					type: "to"
				}
			]
		};
		// console.log(email);
		this.emailSender.send(email);
		this.isSent = true;
	}

	closeModal(modalId?: number) {
		this.modalService.hide(modalId);
	}

	ngOnDestroy() {
		this.unscubscribe.next();
		this.unscubscribe.complete();
	  }

}
