import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IframeModalComponent } from 'src/app/modals/iframe-modal/iframe-modal.component';
import { StandComponent } from 'src/app/modals/stand/stand.component';
import { YoutubeComponent } from 'src/app/modals/youtube/youtube.component';
import { Coords } from '../imagemap/imagemap';
import { ImageScheme } from './image-scheme';

@Component({
  selector: 'app-mobile-images',
  templateUrl: './mobile-images.component.html',
  styleUrls: ['./mobile-images.component.scss']
})
export class MobileImagesComponent implements OnInit {

  @Input() data!: ImageScheme[];
  bsModalRef!: BsModalRef;

  constructor(
    public translate: TranslateService,
    private modalService: BsModalService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  onClick(itemAction: any, itemActionParam: any) {
    switch (itemAction) {
      case 'openVideoModal()':
        this.openVideoModal(itemActionParam)
        break;
      case 'openModal()':
        this.openModal(itemActionParam)
        break;
      case 'goToPage()':
        this.goToPage(itemActionParam)
        break;
      case 'goToExtPage()':
        this.goToExtPage(itemActionParam)
        break;
      case 'openIFrameModal()':
        let hash = itemActionParam.split(',');
        // console.log(hash)
        this.openIFrameModal(hash[0], hash[1])
        break;
      default: this.doNothing();
    }
  }

  public openVideoModal(videoId: string) {
    const initialState = {
      list: [videoId],
    };
    this.bsModalRef = this.modalService.show(YoutubeComponent, {
      initialState,
      class: "modal-lg modal-dialog-centered",
    });
  }

  public doNothing() {

  }

  public openModal(id: any) {
    const initialState = {
      item: id,
      modalId: id
    };
    this.bsModalRef = this.modalService.show(StandComponent, {
      initialState,
      class: "place-modal modal-xxl",
      ignoreBackdropClick: false,
      backdrop: true,
      id: id
    });
  }

  goToExtPage(page: string) {
    window.open(page, "_blank");
  }

  goToPage(page: string) {
    this.router.navigate([`/${page}`]);
  }

  openIFrameModal(frameHash: string, frameId?: string) {
    const initialState = {
      list: { place: frameId, iframe: frameHash },
      modalId: 901,
    };
    this.bsModalRef = this.modalService.show(IframeModalComponent, {
      initialState,
      class: "modal-xl modal-dialog-centered",
      ignoreBackdropClick: true,
      backdrop: true,
      id: 901,
    });
  }


}
