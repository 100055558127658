<div class="desktop d-lg-block d-none">
    <app-imagemap [data]="data"></app-imagemap>
</div>
<div class="mobile d-flex flex-column d-lg-none bg-light">
    <div class="container">
        <div class="row mt-2">
            <div class="col-12 bg-primary">
                <h1 class="text-uppercase text-white text-center mb-0 py-2">{{'pages.expoHall1.title' | translate}}</h1>
            </div>
        </div>
        <div class="row my-4">
            <div class="col-6 mb-4" *ngFor="let item of places;">
                <a [routerLink]="" type="button" (click)="openModal(item.id)">
                    <img [src]="item.image" class="img-fluid">
                </a>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>
