import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CockpitService } from 'src/app/core/cockpit/cockpit.service';
import { Visitor } from 'src/app/core/cockpit/visitor';
import { Categories } from 'src/app/core/urbo/categories';
import { Meetings } from 'src/app/core/urbo/meetings';
import { UrboService } from 'src/app/core/urbo/urbo.service';
import { IframeModalComponent } from 'src/app/modals/iframe-modal/iframe-modal.component';
import { ExhibitorsModalComponent } from './exhibitors-modal/exhibitors-modal.component';
import { VisitorsModalComponent } from './visitors-modal/visitors-modal.component';

@Component({
	selector: 'app-meeting-area',
	templateUrl: './meeting-area.component.html',
	styleUrls: ['./meeting-area.component.scss']
})
export class MeetingAreaComponent implements OnInit, OnDestroy {
	errorMessage: any;

	constructor(
		public translate: TranslateService,
		private modalService: BsModalService,
		private cockpit: CockpitService,
		private urbo: UrboService,
		private titleService: Title
	) { }

	private unscubscribe = new Subject();
	categories!: Categories[];
	visitors!: Visitor[];
	filteredVisitors!: Visitor[];
	public query: any = '';
	meetings!: Meetings[];
	modalId!: number;
	bsModalRef!: BsModalRef;

	public setTitle(newTitle: string) {
		this.titleService.setTitle(newTitle);
	}

	ngOnInit(): void {
		this.getVisitorTypes();
		this.getVisitors();

		this.urbo
			.getMeetings()
			.pipe(takeUntil(this.unscubscribe))
			.subscribe({
				next: (meetings: any) => {
					this.meetings = meetings.data;
					// console.log(this.meetings)
				},
				error: (err) => (this.errorMessage = err),
			});

		this.translate.get('pages.meetingArea.title').subscribe((res: string) => {
			this.setTitle(res);
		});
	}

	getVisitorTypes() {
		this.urbo
			.getCategories()
			.pipe(takeUntil(this.unscubscribe))
			.subscribe({
				next: (cats: any) => {
					this.categories = cats;
					// console.log(this.categories);
					this.mapVisitors()
				},
				error: (err) => (this.errorMessage = err),
			});
	}
	getVisitors() {
		this.cockpit
			.getVisitors()
			.pipe(takeUntil(this.unscubscribe))
			.subscribe({
				next: (visitors: any) => {
					this.visitors = visitors;
					this.filteredVisitors = this.visitors;
				},
				error: (err) => (this.errorMessage = err),
			});
	}
	mapVisitors() {
		this.visitors.map(vis => {
			// vis.businessType = this.getCategoryName(vis.businessType)?.name
			if (vis.businessType == "0") {
				vis.categoryName = "other"
			} else {
				vis.categoryName = this.getCategoryName(vis.businessType)?.name;
			}
			// console.log(vis.businessType)
			// console.log(this.getCategoryName(vis.businessType)?.name)
		})
	}

	getCategoryName(id: any) {
		return this.categories.find(cat => cat.id == id)
	}

	filterItemsByCategory(category: any) {
		// console.log(category)
		if (category.target.value === "-1") {
			this.getVisitors();
		} else {
			this.filteredVisitors = this.visitors.filter(
				(item: Visitor) => item.businessType == category.target.value
			);
		}
	}

	filterItem(value: any) {
		this.filteredVisitors = this.visitors.filter(
			(item) => item.givenName.toLowerCase().indexOf(value.toLowerCase()) > -1
		);
	}

	ngOnDestroy() {
		this.unscubscribe.next();
		this.unscubscribe.complete();
	}

	getPlaceContacts(placeId: number) {
		var placeData: string;
		this.urbo
			.getPlace(placeId)
			.pipe(takeUntil(this.unscubscribe))
			.subscribe({
				next: (place: any) => {
					// console.log(place)
					placeData = place.data;
					this.openMeetingModal(placeData)
				},
				error: (err) => (this.errorMessage = err),
			});
	}

	openMeetingModal(paceData: any) {
		const initialState = {
			data: paceData
		};
		this.bsModalRef = this.modalService.show(ExhibitorsModalComponent, {
			initialState,
			class: "modal-lg modal-dialog-centered",
			ignoreBackdropClick: true,
			backdrop: true,
			id: 101,
		});
	}

	closeModal(modId?: number) {
		if (this.modalId) {
			this.modalService.hide(this.modalId);
		} else {
			this.modalService.hide(modId);
		}
	}

}
