import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoadingIndicatorService } from 'src/app/core/loader/loading-indicator.service';
import { Events } from 'src/app/core/urbo/events';
import { UrboService } from 'src/app/core/urbo/urbo.service';

@Component({
  selector: 'app-event-hall',
  templateUrl: './event-hall.component.html',
  styleUrls: ['./event-hall.component.scss']
})
export class EventHallComponent implements OnInit {

  private unscubscribe = new Subject();
  errorMessage: any;
  loading: boolean = false;

  seminars!: Events[];
  // iframeId: string = "NqVlALDKxIQwu23pBh3UHJPtWX7h4pRn";
  iframeId: string = "fjLJ1AaOpy7PQ97hjiaXNY59NsSx1UPG";
  
  modalId!: number;
  
  

  constructor(
    private urbo: UrboService,
    private modalService: BsModalService,
    public translate: TranslateService,
    private titleService: Title
  ) {}

  closeModal(modId?: number) {
    if (this.modalId) {
      this.modalService.hide(this.modalId);
    } else {
      this.modalService.hide(modId);
    }
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }


  ngOnInit(): void {
    this.urbo
      .getSeminars()
      .pipe(takeUntil(this.unscubscribe))
      .subscribe({
        next: (events: any) => {
          this.seminars = events.data,
          console.log(this.seminars)
        },
        error: (err) => (this.errorMessage = err),
      });
      
      this.translate.get('pages.eventHall.title').subscribe((res: string) => {
        this.setTitle(res);
      });
  }

  ngOnDestroy() {
    this.unscubscribe.next();
    this.unscubscribe.complete();
  }

  //scroller
  leftNavDisabled = false;
  rightNavDisabled = false;
  @ViewChild('nav', { read: DragScrollComponent, static: true }) ds!: DragScrollComponent;

  moveLeft() {
    this.ds.moveLeft();
  }

  moveRight() {
    this.ds.moveRight();
  }

}
