import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Auth } from 'aws-amplify';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/auth/auth.service';
import { SigninComponent } from 'src/app/auth/signin/signin.component';
import { Imagemap } from 'src/app/core/imagemap/imagemap';
import { ImageScheme } from 'src/app/core/mobile-images/image-scheme';
import { NavbarService } from 'src/app/shared/navigation/navbar.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  data: Imagemap = {
    title: "pages.home.title",
    xxl: {
      image: "../../../assets/images/bg/home-xxl.jpg",
      coords: [
        {
          coords: "773,154,773,302,991,315,991,178",
          shape: "poly",
          tooltip: "pages.home.video1",
          action: "openVideoModal()",
          actionParam: "s8hnSJ9p6NU"
        },
        {
          coords: "1227,212,1398,229,1398,351,1225,336",
          shape: "poly",
          tooltip: "pages.home.video2",
          action: "openVideoModal()",
          actionParam: "2gGJUzjWRz0"
        }
      ]
    },
    xl: {
      image: "../../../assets/images/bg/home-xl.jpg",
      coords: [
        {
          coords: "618,123,789,142,789,251,621,240",
          shape: "poly",
          tooltip: "pages.home.video1",
          action: "openVideoModal()",
          actionParam: "s8hnSJ9p6NU"
        },
        {
          coords: "982,168,1119,184,1117,277,979,270",
          shape: "poly",
          tooltip: "pages.home.video2",
          action: "openVideoModal()",
          actionParam: "2gGJUzjWRz0"
        }
      ]
    },
    lg: {
      image: "../../../assets/images/bg/home-lg.jpg",
      coords: [
        {
          coords: "582,116,741,135,740,237,581,225",
          shape: "poly",
          tooltip: "pages.home.video1",
          action: "openVideoModal()",
          actionParam: "s8hnSJ9p6NU"
        },
        {
          coords: "922,161,1050,173,1046,261,919,254",
          shape: "poly",
          tooltip: "pages.home.video2",
          action: "openVideoModal()",
          actionParam: "2gGJUzjWRz0"
        }
      ]
    },
    md: {
      image: "../../../assets/images/bg/home-md.jpg",
      coords: [
        {
          coords: "550,109,704,124,703,223,551,213",
          shape: "poly",
          tooltip: "pages.home.video1",
          action: "openVideoModal()",
          actionParam: "s8hnSJ9p6NU"
        },
        {
          coords: "874,150,996,164,994,249,873,239",
          shape: "poly",
          tooltip: "pages.home.video2",
          action: "openVideoModal()",
          actionParam: "2gGJUzjWRz0"
        }
      ]
    }
  }

  mobileImages: ImageScheme[] = [
    {
      image: "../../../assets/images/mobile/bulgaria-banner.png",
      size: "2x",
      tooltip: "",
      action: "openVideoModal()",
      actionParam: "s8hnSJ9p6NU"
    },
    {
      image: "../../../assets/images/mobile/bubspa-banner.png",
      size: "1x",
      tooltip: "",
      action: "doNothing()",
      actionParam: ""
    },
    {
      image: "../../../assets/images/mobile/bhra-banner.png",
      size: "1x",
      tooltip: "",
      action: "doNothing()",
      actionParam: ""
    },
    {
      image: "../../../assets/images/mobile/sabre-banner.png",
      size: "1x",
      tooltip: "",
      action: "doNothing()",
      actionParam: ""
    },
    {
      image: "../../../assets/images/mobile/ek-banner.png",
      size: "1x",
      tooltip: "",
      action: "doNothing()",
      actionParam: ""
    }
  ]
  bsModalRef!: BsModalRef;

  constructor(
    public translate: TranslateService,
    private titleService: Title,
    private auth: AuthService,
    public nav: NavbarService
  ) { }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  ngOnInit(): void {
    this.translate.get(this.data.title).subscribe((res: string) => {
      this.setTitle(res);
    });
    this.nav.hide();
  }

  signIn() {
    this.auth.checkUser();
  }

  ngOnDestroy() {
    this.nav.show();
  }
}
