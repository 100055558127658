<div class="modal-content" [ngStyle]="{'background-image': (place?.company_address) ? 'url(' + place?.company_address + ')' : ''}">

    <div class="modal-body d-flex flex-column">
        <a [routerLink]="" class="ml-auto mt-5 pt-5 mt-lg-0 pt-lg-0" (click)="closeModal(place.id)"><img src="../../../assets/images/icons/client-back.png" class="icon-75" /></a>
        <div class="slide-panel info-panel" *ngIf="infoVisible" [@slideInOut]>
            <div class="slide-panel-header">
                <img src="../../../assets/images/logos/expo-summer-logo-white-w125.png" class="img-fluid" />
                <button type="button" class="btn-close float-end btn-close-white p-4" data-bs-dismiss="modal" aria-label="Close" (click)="toggleInfo()"></button>
            </div>
            <div class="slide-panel-body">
                <h3>{{place?.name}}</h3>
                <div [innerHTML]="place!.info | keepHtml"></div>
            </div>
            <div class="slide-panel-footer">

            </div>
        </div>
        <div class="slide-panel info-panel" *ngIf="offersVisible" [@slideInOut]>
            <div class="slide-panel-header">
                <img src="../../../assets/images/logos/expo-summer-logo-white-w125.png" class="img-fluid" />
                <button type="button" class="btn-close float-end btn-close-white p-4" data-bs-dismiss="modal" aria-label="Close" (click)="toggleOffers()"></button>
            </div>
            <div class="slide-panel-body">
                <h3>{{place?.name}}</h3>
                <div class="offers-list d-flex flex-column">
                    <a [routerLink]="" class="offers-list-item d-flex justify-content-between text-decoration-none mb-3" *ngFor="let item of offers">
                        <div class="title px-2 py-1 h6 mb-0 d-flex align-items-center justify-content-start">{{item.title}}</div>
                        <div class="button p-2 bg-primary text-white d-flex align-items-center justify-content-center" (click)="openIframe(item.id, place!.company_name)" >{{ 'misc.view' | translate }}</div>
                    </a>
                </div> 
            </div>
            <div class="slide-panel-footer">

            </div>
        </div>
        <div class="align-self-end w-100 d-none d-lg-block">
            <a *ngIf="specailOffer" [routerLink]="" class="place-icon position-absolute special-offer-btn" (click)="openIframe(specailOffer.id, place!.company_name)" [ngClass]="{'active': infoVisible}">
                <img src="../../../assets/images/icons/Exclusive.png" class="icon-150" />
            </a>
        </div>
        <div class="place-icons d-flex mt-auto justify-content-center">
            <a *ngIf="place?.info" [routerLink]="" class="place-icon" (click)="toggleInfo()" [ngClass]="{'active': infoVisible}">
                <img src="../../../assets/images/icons/client-companyInfo.png" class="icon-100" />
            </a>
            <a *ngIf="offers" [routerLink]="" class="place-icon" (click)="toggleOffers()" [ngClass]="{'active': offersVisible}">
                <img src="../../../assets/images/icons/client-offers.png" class="icon-100" />
            </a>
            <a *ngIf="place?.email" href="mailto:{{place?.email}}" class="place-icon">
                <img src="../../../assets/images/icons/client-contact.png" class="icon-100" />
            </a>
            <a *ngIf="place?.fb_link" [routerLink]="" class="place-icon" (click)="openChat(place.fb_link)">
                <img src="../../../assets/images/icons/client-chat.png" class="icon-100" />
            </a>
            <ng-template #visitorsTemplate>
                <div class="h4 text-white text-center">{{ 'misc.visitors' | translate }}: <br /><strong class="font-weight-bolder">{{place?.api_views}}</strong></div>
            </ng-template>
            <a *ngIf="place?.api_views" [routerLink]="" class="place-icon" [popover]="visitorsTemplate" triggers="focus">
                <img src="../../../assets/images/icons/client-visitors.png" class="icon-100" />
            </a>
            <a *ngIf="place?.yt_link" [routerLink]="" class="place-icon" (click)="openVideo(place.yt_link)">
                <img src="../../../assets/images/icons/Video.png" class="icon-100" />
            </a>
            <a *ngIf="place?.insta_link" href={{place?.insta_link}} target="_blank" class="place-icon">
                <img src="../../../assets/images/icons/client-catalog.png" class="icon-100" />
            </a>
            <a *ngIf="specailOffer" [routerLink]="" class="place-icon d-lg-none" (click)="openIframe(specailOffer.id, place!.company_name)" [ngClass]="{'active': infoVisible}">
                <img src="../../../assets/images/icons/Exclusive.png" class="icon-100" />
            </a>
        </div>
    </div>
</div>
