import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-iframe-modal',
  templateUrl: './iframe-modal.component.html',
  styleUrls: ['./iframe-modal.component.scss']
})
export class IframeModalComponent implements OnInit {

  iframeUrl: any;

  constructor(
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    public translate: TranslateService,
    private sanitizer: DomSanitizer
  ) { }
  modalId!: number;
  list: any = {};
  public placeId!: string;
  public iframeId!: string;
  curLang!: string;

  ngOnInit(): void {
    this.modalId = this.modalId;
    if (this.list.place) {
      this.placeId = this.list.place;
    } else {
      this.placeId = '';
    }
    
    this.iframeId = this.list.iframe;

    if (this.translate.currentLang) {
      this.curLang = this.translate.currentLang;
    } else {
      this.curLang = this.translate.getDefaultLang();
    }
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://embed.urboapp.com/${this.iframeId}/${this.curLang}/${this.placeId}`);
  }

  closeModal(modId?: number) {
    if (this.modalId) {
      this.modalService.hide(this.modalId);
    } else {
      this.modalService.hide(modId);
    }
  }

}
