<div class="table-responsive max-vh-50 overflow-auto custom-scroll">
  <table class="table text-white ">
    <thead>
      <tr>
        <th scope="col">First</th>
        <th scope="col">Last</th>
        <th scope="col">Company</th>
        <th scope="col">Type</th>
      </tr>
    </thead>
    <tbody class=" ">

      <tr role="button" *ngFor="let visitor of visitors | visitors: query" (click)="openModal(visitor._id)">
        <td scope="row">{{visitor.givenName}}</td>
        <td>{{visitor.familyName}}</td>
        <td>{{visitor.company}}</td>
        <td>{{visitor.categoryName}}</td>
      </tr>
    </tbody>
  </table>
</div>
