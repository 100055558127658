import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { FullVisitor } from 'src/app/core/cockpit/visitor';
import { Email } from 'src/app/core/mailchimp/email';
import { MailchimpService } from 'src/app/core/mailchimp/mailchimp.service';
import { Categories } from 'src/app/core/urbo/categories';
import { UrboService } from 'src/app/core/urbo/urbo.service';

@Component({
  selector: 'app-exhibitors-modal',
  templateUrl: './exhibitors-modal.component.html',
  styleUrls: ['./exhibitors-modal.component.scss']
})
export class ExhibitorsModalComponent implements OnInit {
  data!: any;
  errorMessage: any;

	constructor(
		private modalService: BsModalService,
		public translate: TranslateService,
		private emailSender: MailchimpService,
		private auth: AuthService,
		private urbo: UrboService,
	) { }

	bsModalRef!: BsModalRef;
	private unscubscribe = new Subject();
	visitor!: FullVisitor;
	categories!: Categories[];
	currentVisitor!: any;
	currentVisitorCategory!: any;
	message: string = '';
  timeSlot: string = '';
  contactBy: string = '';
	id!: any;
	emailMessage!: Email;
	isSent: boolean = false;

	

	ngOnInit(): void {
		this.getCurrentUser();
	}

	async getCurrentUser() {
		this.auth.getCurrentUser().then(user => {
			this.currentVisitor = user.attributes;
			this.getVisitorTypes()

		}).catch(err => {
			console.log(err)
		});
	}

	getVisitorTypes() {
		this.urbo
			.getCategories()
			.pipe(takeUntil(this.unscubscribe))
			.subscribe({
				next: (cats: any) => {
					this.categories = cats;
					this.currentVisitorCategory = this.getCategoryName(this.currentVisitor['custom:type_of_business']);
				},
				error: (err) => (this.errorMessage = err),
			});
	}

	getCategoryName(id: any) {
		return this.categories.find(cat => cat.id == id)
	}

	sendContact(timeSlot:string, contactBy: string, message: string) {

		const emailBody: string = `
		Здравейте,<br /><br />

		Получихте заявка за среща от:<br />
		Име: ${this.currentVisitor.name} ${this.currentVisitor.family_name}<br />
		Компания: ${this.currentVisitor['custom:company']}<br />
		Позиция: ${this.currentVisitor['custom:position']}<br />
		Тип Бизнес: ${this.currentVisitorCategory.name}<br />
		Телефон: ${this.currentVisitor['custom:phone']}<br />
		E-mail: ${this.currentVisitor.email}<br />
		Град/Държава: ${this.currentVisitor['custom:city']}<br /><br />
		
    Предпочитан час: ${timeSlot}<br />
    Предпочита да се свържете чрез: ${contactBy}<br />
    Съобщение: ${message}<br /><br />


		С най-добри пожелания,<br />
		Holiday & SPA Virtual Show Team<br /><br />

		====================================<br /><br />

		Hello,<br /><br />

		You received a request meeting from:<br />
		Name: ${this.currentVisitor.name} ${this.currentVisitor.family_name}<br />
		Company: ${this.currentVisitor['custom:company']}<br />
		Position: ${this.currentVisitor['custom:position']}<br />
		Business Type: ${this.currentVisitorCategory.name}<br />
		Phone: ${this.currentVisitor['custom:phone']}<br />
		E-mail: ${this.currentVisitor.email}<br />
		City / Country: ${this.currentVisitor['custom:city']}<br /><br />

		Preferred time slot: ${timeSlot}<br />
    Preferred contact channel: ${contactBy}<br />
    Message: ${message}<br /><br />
		
		If this is of interest to you, you can contact through one of the channels specified by the applicant!<br /><br />

		With best wishes,<br />
		Holiday & SPA Virtual Show Team<br />
		`
		const email: Email = {
			from_email: "no-reply@travelmarket.bg",
			from_name: "Holiday & SPA Virtual Show Team",
			subject: "Запитване за среща / Request for meeting",
			html: emailBody,
			to: [
				{
					email: this.data.email,
					type: "to"
				}
			]
		};
		this.emailSender.send(email);
		this.isSent = true;
	}

	closeModal(modalId?: number) {
		this.modalService.hide(modalId);
	}

	ngOnDestroy() {
		this.unscubscribe.next();
		this.unscubscribe.complete();
	  }

}
