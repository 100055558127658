import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-uptmp.component.html',
  styleUrls: ['./sign-uptmp.component.scss']
})
export class SignUptmpComponent implements OnInit {

  email!:string;
  password!:string;
  givenName!:string;
  familyName!:string;
  phone!:string;
  constructor(private router:Router) { }

  ngOnInit(): void {
  }

  register(){
    try {
      const user = Auth.signUp({
        username: this.email,
        password: this.password,
        attributes: {
          email: this.email,
          name: this.givenName,
          family_name: this.familyName,
          phone_number: this.phone
        }
      });
      // console.log({ user });
      alert('User signup completed , please check verify your email.');
      this.router.navigate(['login']);
    } catch (error) {
      console.log('error signing up:', error);
    }
  }


}
