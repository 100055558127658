<div class="modal-header border-bottom-0 py-4">
    <img src="../../../assets/images/logos/expo-summer-logo-white-w125.png">
    <app-langswitch class="mt-n5"></app-langswitch>
</div>
<form name="form" #registrationForm="ngForm" (ngSubmit)="registrationForm.form.valid && onSubmit()" novalidate>
    <div class="modal-body">
        <h4 class="text-white text-uppercase">{{'auth.registerNow' | translate}}</h4>

        <!-- <div class="invalid-feedback d-block badge bg-danger" *ngIf="!isValid"> -->
        <div class="invalid-feedback d-block badge bg-danger mb-1" *ngIf="!isValid">
            {{errorMessage | translate}}
        </div>
        <div class="form-floating mb-1">
            <input type="text" name="givenName" class="form-control rounded-0 border-0" [(ngModel)]="givenName" required placeholder="{{'auth.firstName' | translate}}" #name="ngModel">
            <label for="givenName">{{'auth.firstName' | translate}} <span class="text-danger">*</span></label>
        </div>
        <div class="form-floating mb-1">
            <input type="text" name="familyName" class="form-control rounded-0 border-0" [(ngModel)]="familyName" required placeholder="{{'auth.lastName' | translate}}">
            <label for="familyName">{{'auth.lastName' | translate}} <span class="text-danger">*</span></label>
        </div>
        <div class="form-floating mb-1">
            <input type="text" name="company" class="form-control rounded-0 border-0" [(ngModel)]="company" required placeholder="{{'auth.company' | translate}}">
            <label for="company">{{'auth.company' | translate}} <span class="text-danger">*</span></label>
        </div>
        <div class="form-floating mb-1">
            <input type="text" name="position" class="form-control rounded-0 border-0" [(ngModel)]="position" required placeholder="{{'auth.position' | translate}}">
            <label for="position">{{'auth.position' | translate}} <span class="text-danger">*</span></label>
        </div>
        <div class="form-floating mb-1">
            <input type="text" name="phone" class="form-control rounded-0 border-0" [(ngModel)]="phone" required placeholder="{{'auth.phone' | translate}}">
            <label for="phone">{{'auth.phone' | translate}} <span class="text-danger">*</span></label>
        </div>
        <div class="mb-1 form-floating">
            <input type="text" name="city" class="form-control rounded-0 border-0" [(ngModel)]="city" placeholder="{{'auth.city' | translate}}">
            <label for="city">{{'auth.city' | translate}}</label>
        </div>
        <div class="mb-2 form-floating">
            <select name="businessType" class="form-select  border-0 rounded-0" id="floatingSelect" [(ngModel)]="businessType" aria-label="Floating label select example">
                <option selected value="0">{{'auth.typeOfBusiness' | translate}}</option>
                <option *ngFor="let types of categories" [value]="types.id">{{types.name}}</option>
                <option selected value="0">{{'auth.types.other' | translate}}</option>
            </select>
            <label for="floatingSelect">{{'auth.typeOfBusiness' | translate}}</label>
        </div>
        <div class="">
            <div class="form-check">
                <input name="toc" class="form-check-input" type="checkbox" value="" [(ngModel)]="toc" id="toc" required>
                <label class="form-check-label" for="toc">
                    {{'auth.agreeTOC.0' | translate}} <a class="text-white" [href]="'auth.agreeTOC.1' | translate" target="_blank">{{'auth.agreeTOC.2' | translate}}</a> <span class="text-danger">*</span>
                </label>
            </div>
        </div>
        <div class="form-floating mb-1">
            <input type="email" name="email" class="form-control rounded-0 border-0" [(ngModel)]="email" required placeholder="{{'auth.email' | translate}}">
            <label for="email">{{'auth.email' | translate}} <span class="text-danger">*</span></label>
        </div>
        <div ngModelGroup="passwords" #passwords="ngModelGroup" appCheckPassword>
            <div class="form-floating mb-1">
                <input type="password" class="form-control rounded-0 border-0" #passwordA="ngModel" name="passwordA" required id="passwordA" [(ngModel)]="password1" required placeholder="{{'auth.password' | translate}}">
                <label for="password">{{'auth.password' | translate}} <span class="text-danger">*</span></label>
            </div>
            <div class="form-floating mb-1">
                <input type="password" class="form-control rounded-0 border-0" #passwordB="ngModel" name="passwordB" required id="passwordB" [(ngModel)]="password2" placeholder="{{'auth.confirmPassword' | translate}}">
                <label for="passwordB">{{'auth.confirmPassword' | translate}} <span class="text-danger">*</span></label>
            </div>
            <div *ngIf="passwords.errors?.passwordCheck && (passwordB.dirty || passwordB.touched)" class="invalid-feedback d-block badge bg-danger mb-1">
                {{'auth.matchPasswords' | translate}}
            </div>
        </div>
        <div class="">
            <div class="form-check">
                <input class="form-check-input" name="rememberMe" type="checkbox" value="" [(ngModel)]="remember" id="remember">
                <label class="form-check-label" for="remember">
                    {{'auth.rememberMe' | translate}}
                </label>
            </div>
        </div>

    </div>
    <div class="modal-footer d-flex justify-content-between">
        <button type="button" class="btn btn-link text-white" (click)="signIn()">{{'auth.login' | translate}}</button>
        <button type="button" class="btn btn-secondary text-uppercase" type="submit" [disabled]="!registrationForm.form.valid">{{'auth.registration' | translate}}</button>
    </div>
</form>
