<div class="d-flex position-relative" *ngIf="item.trailer_link; else novideo">
    <div class="position-absolute d-flex w-100 h-100 m-auto" role="button" (click)="openModalWithVideo(item.trailer_link)">
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-play-btn m-auto text-white" viewBox="0 0 16 16">
            <path d="M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
        </svg>
    </div>
    <img [src]="item?.image" class="card-img-top rounded-2"  />
</div>
<ng-template #novideo>
    <img [src]="item?.image" class="card-img-top rounded-2" />
</ng-template>

<div class="card-body p-0 pt-4">
    <h6 class="card-title text-wrap text-white">{{item?.title}}</h6>
</div>
<div class="card-footer bg-transparent border-0 p-0">
    <a [routerLink]="" (click)="openIframe(item.id)" class="btn btn-secondary w-100">{{'misc.viewMore' | translate}}</a>
</div>
