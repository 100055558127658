import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Auth } from 'aws-amplify';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CockpitService } from 'src/app/core/cockpit/cockpit.service';
import { FullVisitor } from 'src/app/core/cockpit/visitor';
import { Categories } from 'src/app/core/urbo/categories';
import { UrboService } from 'src/app/core/urbo/urbo.service';
import { SigninComponent } from '../signin/signin.component';

@Component({
	selector: 'app-signup',
	templateUrl: './signup.component.html',
	styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, OnDestroy {


	constructor(
		public bsModalRef: BsModalRef,
		private modalService: BsModalService,
		public translate: TranslateService,
		private cockpit: CockpitService,
		private urbo: UrboService
	) { }

	typesOfBusiness = [
		"tourOperators",
		"travelAgencies",
		"hotelAndResorts",
		"spaAndWellness",
		"municipalities",
		"wineTourism",
		"attractions",
		"equipmentAndAccessories",
		"specializedSoftware",
		"transport",
		"regionalTourismCouncils",
		"branchOrganizations",
		"traveller",
		"blogger",
		"journalist"
	]

	email!: string;
	password1!: string;
	givenName!: string;
	familyName!: string;
	company!: string;
	position!: string;
	phone!: string;
	city!: string;
	businessType: string = "0";
	toc!: string;
	remember!: string;
	password2!: string;
	isValid: boolean = true;
	errorMessage: string = 'invalidData';
	model: any = {};
	visitor!: FullVisitor;
	private unscubscribe = new Subject();
	categories!: Categories[];


	ngOnInit(): void {
		this.urbo
			.getCategories()
			.pipe(takeUntil(this.unscubscribe))
			.subscribe({
				next: (cats: any) => {
					// console.log(cats);
					this.categories = cats;
					// console.log(this.categories);
				},
				error: (err) => (this.errorMessage = err),
			});
	}

	onSubmit() {
		this.register();
	}

	async register() {
		try {
			const { user } = await Auth.signUp({
				username: this.email,
				password: this.password1,
				attributes: {
					email: this.email,
					name: this.givenName,
					family_name: this.familyName,
					"custom:phone": this.phone,
					"custom:company": this.company,
					"custom:position": this.position,
					"custom:type_of_business": this.businessType,
					"custom:city": this.city
				}
			});
			this.visitor = {
				givenName: this.givenName,
				familyName: this.familyName,
				company: this.company,
				position: this.position,
				businessType: this.businessType,
				city: this.city,
				email: this.email,
				phone: this.phone
			}
			this.addVisitor(this.visitor);
			console.log(user)
			this.isValid = true;
			this.signIn();
		} catch (error) {
			console.log('error signing up:', error);
			this.isValid = false;
			if (error.code) {
				// this.errorMessage = error.code;
				switch (error.message) {
					case "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
					  this.translate.get('auth.longerPass').subscribe((res: string) => {
						this.errorMessage = res;
					  });
					  console.log(error.message);
					  break;
					// case 'Password did not conform with policy: Password not long enough':
					//   console.log(error.message);
					//   break;
					// case 'User is not confirmed.':
					//   this.translate.get('auth.notConfirmed').subscribe((res: string) => {
					// 	this.errorMessage = res;
					//   });
					//   console.log(error.message);
					//   break;
					// case 'Incorrect username or password.':
					//   console.log(error.message);
					//   this.translate.get('auth.incorrect').subscribe((res: string) => {
					// 	this.errorMessage = res;
					//   });
					//   break;
					// case 'User does not exist.':
					//   console.log(error.message);
					//   this.translate.get('auth.notExists').subscribe((res: string) => {
					// 	this.errorMessage = res;
					//   });
					//   break;
					default:
					  this.translate.get('auth.checkFields').subscribe((res: string) => {
						this.errorMessage = res;
					  });
					  console.log(error.message);
				  }

			}
		}
	}

	addVisitor(visitor: FullVisitor) {
		this.cockpit.saveVisitor(visitor).subscribe({
			next: (entry: any) => { },
			error: (err) => (this.errorMessage = err),
		});
	}

	signIn() {
		this.bsModalRef.hide();
		this.bsModalRef = this.modalService.show(SigninComponent, { class: "modal-md modal-dialog-centered", backdrop: "static" });
	}

	ngOnDestroy() {
		this.unscubscribe.next();
		this.unscubscribe.complete();
	}
}

