<div id="bg">
    <img [src]="imageData.image" class="full-screen" [alt]="data.title | translate" usemap="#bg-image" (load)="imageResized();">
</div>
<!-- {{imageData.coords | json}} -->
<map name="bg-image">
    <area *ngFor="let coords of imageData.coords" [coords]="coords.coords" [shape]="coords.shape" (click)="onClick(coords)" (mouseenter)="showTooltips(coords.tooltip);" (mouseleave)="hideTooltips();" (mousemove)="mouseCoordinates($event)" [routerLink]="" >
</map>
<div class="tooltip bs-tooltip-top fade show" role="tooltip" [style.top.px]="top" [style.left.px]="left" *ngIf="showTooltip" #ttp>
    <div class="tooltip-inner bg-dark text-white" >
        {{tooltip}}
    </div>
</div>
