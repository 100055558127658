import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-game-modal',
  templateUrl: './game-modal.component.html',
  styleUrls: ['./game-modal.component.scss']
})
export class GameModalComponent implements OnInit {

  constructor(
    private modalService: BsModalService,
  ) { }
  content!: string;
  bsModalRef!: BsModalRef;

  ngOnInit(): void {
  }
  closeModal(modalId?: number){
    this.modalService.hide(modalId);
  }

}
