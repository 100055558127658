import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MonoTypeOperatorFunction, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Categories } from './categories';
import { Events } from './events';
import { Images } from './images';
import { Kiosks } from './kiosks';
import { Meetings } from './meetings';
import { Offers } from './offers';
import { Places } from './places';
import { StandVisitor } from './stand-visitor';


@Injectable({
  providedIn: 'root'
})
export class UrboService {
  
  constructor(private http: HttpClient, public translate: TranslateService) { }

  public lang: string = this.translate.getDefaultLang();

  private catUrl = `${environment.urboApiUrl}categories/get?main_category_id=20&lang=`;
  private placesUrl = `${environment.urboApiUrl}places/get?main_category_id=20&lang=`; 
  private kioskUrl = `${environment.urboApiUrl}places/get?main_category_id=20&category_id=155&lang=`;

  private addVisitor = `${environment.urboApiUrl}places/addVisitor`;

  currentLang() {
    if (this.translate.currentLang) {
      this.lang = this.translate.currentLang;
    } else {
      this.lang = this.translate.getDefaultLang();
    }
    return this.lang;
  }


  addingVisitor(visitor: StandVisitor): Observable<StandVisitor> {
    // console.log(visitor)
    return this.http.post<any>(this.addVisitor, visitor).pipe(
      catchError(this.handleError)
    )
  }

  getCategories(): Observable<Categories> {
    this.currentLang();
    return this.http.post<any>(this.catUrl + this.lang, '').pipe(
      // tap(categories => console.log(categories)),
      map(categories => categories.data.filter((c: { id: number; }) => c.id !== 155 && c.id !== 156 && c.id !== 146)),
      catchError(this.handleError)
    )
  }

  getPlacesHall1(): Observable<Places> {
    this.currentLang();
    return this.http.post<any>(this.placesUrl + this.lang, '').pipe(
      // map(places => places.data.filter((c: { category_id: number; iban: any; }) => c.category_id !== 155 && c.category_id !== 156 && c.category_id !== 146 && c.iban == 1)),
      map(places => places.data.filter((c: { category_id: number; iban: any; }) => c.category_id !== 155 && c.category_id !== 156 && c.iban == 1)),
      catchError(this.handleError)
    )
  }
  getPlacesHall2(): Observable<Places> {
    this.currentLang();
    return this.http.post<any>(this.placesUrl + this.lang, '').pipe(
      // map(places => places.data.filter((c: { category_id: number; iban: any; }) => c.category_id !== 155 && c.category_id !== 156 && c.category_id !== 146 && c.iban == 1)),
      map(places => places.data.filter((c: { category_id: number; iban: any; }) => c.category_id !== 155 && c.category_id !== 156 && c.iban == 2)),
      catchError(this.handleError)
    )
  }

  getPlace(id:number): Observable<Places> {
    this.currentLang();
    console.log(id)
    return this.http.post<any>(`${environment.urboApiUrl}places/get?id=${id}&lang=${this.lang}`, '').pipe(
      catchError(this.handleError)
    )
  }

  getKiosks(): Observable<Kiosks> {
    this.currentLang();
    return this.http.post<any>(this.kioskUrl + this.lang, '').pipe(
      catchError(this.handleError)
    )
  }

  incrementView(id: any): Observable<Places> {
    this.currentLang();
    return this.http.post<any>(`${environment.urboApiUrl}places/get?id=${id}&lang=${this.lang}`, '').pipe(
      catchError(this.handleError)
    )
  }

  getMeetings(): Observable<Meetings> {
    this.currentLang();
    return this.http.post<any>(`${environment.urboApiUrl}events/getEvents?event_type_id=127&lang=${this.lang}`, '').pipe(
      catchError(this.handleError)
    )
  }

  getSeminars(): Observable<Events> {
    this.currentLang();
    return this.http.post<any>(`${environment.urboApiUrl}events/getEvents?event_type_id=128&lang=${this.lang}`, '').pipe(
      catchError(this.handleError)
    )
  }

  getMovies(): Observable<Events> {
    this.currentLang();
    return this.http.post<any>(`${environment.urboApiUrl}events/getEvents?event_type_id=129&lang=${this.lang}`, '').pipe(
      catchError(this.handleError)
    )
  }

  getImages(): Observable<Images> {
    this.currentLang();
    return this.http.post<any>(`${environment.urboApiUrl}events/getEvents?event_type_id=130&lang=${this.lang}`, '').pipe(
      catchError(this.handleError)
    )
  }

  getOffers(id: any): Observable<Offers> {
    this.currentLang();
    return this.http.post<any>(`${environment.urboApiUrl}events/getEvents?place_id=${id}&event_type_id=125&lang=${this.lang}`, '').pipe(
      catchError(this.handleError)
    )
  }

  getSpecialOffers(id: any): Observable<Offers> {
    this.currentLang();
    return this.http.post<any>(`${environment.urboApiUrl}events/getEvents?place_id=${id}&event_type_id=126&lang=${this.lang}`, '').pipe(
      catchError(this.handleError)
    )
  }

  private handleError( err: HttpErrorResponse) {
    let errorMessage = '';

    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occure: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }

}
